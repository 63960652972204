import clsx from "clsx";

import CoinIcon from "../../assets/images/coin-icon.png";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
  badge: {
    position: 'absolute',
    zIndex: 4,
  },
  mainIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  protocolIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: 'white',
    position: 'relative',
    left: '38px',
    bottom: '40px'
  },
  noSecondIcon: {
    left: '20px',
  },
  secondIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: 'relative',
    right: theme.spacing(2),
    zIndex: 2,
  },

}));

interface IconBadgeProps {
  mainIcon: string;
  secondIcon?: string;
  protocolIcon?: string;
  align?: string;

}

export const IconBadge = (props: IconBadgeProps) => {
  const { mainIcon, protocolIcon, secondIcon } = props;

  const addDefaultSrc = (event: any) => {
    event.target.src = CoinIcon;
  };

  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <img src={mainIcon} alt='' className={classes.mainIcon} onError={addDefaultSrc} />
      {protocolIcon && (
        <div className={classes.badge}>
          <img src={protocolIcon} alt="" onError={addDefaultSrc} className={clsx(classes.protocolIcon, !secondIcon && classes.noSecondIcon)} />
        </div>
      )}
      {secondIcon && (
        <img src={secondIcon} onError={addDefaultSrc} alt="" className={classes.secondIcon} />
      )}
    </div>
  );
};
