import { Typography, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import canvasLogo from "../../assets/logos/canvas-logo.svg";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#333042',
    zIndex: 2000,
    position: 'relative',
  },
  logoTitle: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '2rem',
    fontWeight: 500,
    '& span': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontWeight: 300
    }
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,
    '& img': {
      height: 50,
      width: 70,
      marginRight: 13,
    },
  },
  progress: {
    paddingTop: '2px'
  },
  buttons: {
    display: 'block'
  },
  btnLogin: {
    width: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  btnSignUp: {
    width: 120,
    color: 'rgba(255, 255, 255, 0.7)',
    fontWeight: 300,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
}),
);

export const MainPageLoader = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoWrapper}>
        <div><img src={canvasLogo} alt="" /></div>
        <Typography variant="h1" className={classes.logoTitle}>CANVAS <span>Console</span></Typography>
      </div>
      <div className={classes.progress}>
        <CircularProgress color="primary" size={35} />
      </div>
    </div>
  );
};
