import { useApiTradeTransactions } from '../../../../hooks/prime/useApiTradeTransactions';

import { TradesTable } from './TradesTable';

import Paper from "@mui/material/Paper";

export const PrimeTrades = (props: any) => {
  const { userId } = props;
  const { data, isLoading, mutate } = useApiTradeTransactions(userId);
  return (
    <Paper>
      <TradesTable data={data} isLoading={isLoading} mutate={mutate} />
    </Paper>
  );
}