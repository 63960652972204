import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";

import { UserTabs } from "./UserTabs";

import { makeStyles } from 'tss-react/mui';
import { UserProfile } from './UserProfile';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  }
}));

export const User = () => {
  const { userId } = useParams();
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        {!userId && (
          <CanvasSkeleton className={classes.skeleton} />
        )}
        {userId && (
          <>
            <UserProfile userId={userId} />
            <UserTabs userId={userId} />
          </>
        )}
      </div>
    </AppLayout>
  );
};
