import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import { IconBadge } from "../../../../shared/UI/IconBadge";
import FireblocksIcon from "../../../../assets/networks/fireblocks.png";
import CanvasIcon from "../../../../assets/logos/canvas-logo.svg";
import CopyIcon from "../../../../assets/images/copy-icon.png";

import EditExchangeModal from './EditExchangeModal';
import DeleteExchangeModal from './DeleteExchangeModal';

import { makeStyles } from 'tss-react/mui';
import Tooltip from "@mui/material/Tooltip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';

const useStyles = makeStyles()((theme) => ({
  overflow: {
    width: '100%',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2),
  },
  selected: {
    backgroundColor: theme.palette.info.light,
  },
}));

const shrinkUUID = (uuid: any) => {
  if (uuid) {
    return `${uuid.substring(0, 6)}...${uuid.substring(uuid.length - 4)}`;
  }
  return '';
};

const CopyTool = (props: any) => {
  const [copied, setCopied] = useState("");
  return (
    <>
      <Tooltip title={copied === props.text ? "Copied!" : "Copy"} placement="top">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(props.text);
            setCopied(props.text);
            setTimeout(() => setCopied(''), 3000);
          }}
        >
          <img src={CopyIcon} alt="copy-icon" />
        </IconButton>
      </Tooltip>
    </>
  );
};

const ExchangeTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState<any>();

  const exchanges = useMemo(() => data?.filter((x: any) => x.secret === props.secretId), [data, props.secretId]);

  // console.log('data', data, exchanges);

  useEffect(() => {
    if (!props.secretId) {
      setSelectedExchange(undefined);
    }
  }, [props.secretId]);

  useEffect(() => {
    if (selectedExchange) {
      if (!exchanges?.find((x: any) => x.exchangeId === selectedExchange?.exchangeId)) {
        setSelectedExchange(undefined);
      }
    }
  }, [selectedExchange, exchanges]);

  const handleAddNew = () => {
    console.log('handleAddNew');
    setSelectedExchange(undefined);
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    setSelectedExchange(data?.find((x: any) => x.exchangeId === id));
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    setSelectedExchange(data?.find((x: any) => x.exchangeId === id));
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    setSelectedExchange(undefined);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    setSelectedExchange(undefined);
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    setSelectedExchange(undefined);
    mutate();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    setSelectedExchange(undefined);
    mutate();
  };

  const { classes } = useStyles();
  return (
    <>
        <div className={classes.overflow}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}></TableCell>
                <TableCell className={classes.cell} align="left"><b>ID</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Fireblocks Key</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Exchange UUID</b></TableCell>
                <TableCell className={classes.cell} align="left"><b>Description</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Updated</b></TableCell>
                <TableCell className={classes.cell} align="right"><IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exchanges.map((row: any) => (
                <TableRow key={row.exchangeId}>
                  <TableCell className={classes.cell} align="left"><IconBadge mainIcon={CanvasIcon} protocolIcon={FireblocksIcon} /></TableCell>
                  <TableCell className={classes.cell} align="left" component="th" scope="row"><b>{row.exchangeId}</b>  <CopyTool text={row.exchangeId} /></TableCell>
                  <TableCell className={classes.cell} align="center">{row.secret}</TableCell>
                  <TableCell className={classes.cell} align="center">{shrinkUUID(row.exchange)}</TableCell>
                  <TableCell className={classes.cell} align="left">{row.description}</TableCell>
                  <TableCell className={classes.cell} align="center">{format(new Date(row.updatedAt), 'dd-MMM-yyyy, H:mm a')}</TableCell>
                  <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                    <IconButton onClick={() => handleOpenEditModal(row.exchangeId)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleOpenDeleteModal(row.exchangeId)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      <EditExchangeModal exchange={selectedExchange} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} secretId={props.secretId} />
      <DeleteExchangeModal exchange={selectedExchange} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
export default ExchangeTable;