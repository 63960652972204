import useSWR from 'swr';
import { useAuthStore } from "../context/useAuthStore";
import { getFeePolicies } from "../../services/connect-fees";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiFeePolicyList = ([_]: any[]) => getFeePolicies();

export const useApiFeePolicyList = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiFeePolicyList'] : null, apiFeePolicyList, options);
  return {
    data: data ? data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
