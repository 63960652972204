import useSWR from 'swr';

import { useAuthStore } from "../context/useAuthStore";
import { getTransactions } from "../../services/connect-user";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiTransactions = ([_, starkkey]: [string, string]) => getTransactions(starkkey);

export const useApiUserTransactions = (starkkey: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && starkkey ? ['apiTransactions', starkkey] : null, apiTransactions, options)
  return {
    data: data?.l2 || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
