import { useState } from 'react';
import { format } from 'date-fns';

import { shrinkAddress } from '../../../../helpers/shrink';

import { IconBadgeSml } from '../../../../shared/UI/IconBadgeSml';

import CoinIcon from "../../../../assets/images/coin-icon.png";
import CopyIcon from "../../../../assets/images/copy-icon.png";

import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  overflow: {
    width: '100%',
    overflow: 'scroll-x',
  },
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2),
  },
  label: {
    fontWeight: 500
  },
  symbol: {
    fontWeight: 500
  },
  address: {
    fontSize: '0.75rem',
    color: '#808191',
  },
  amount: {
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 700
    }
  },
}));

const numToFixed = (s: string, toFixed: number) => {
  const num = Number(s);
  if (!isNaN(num)) {
    return num.toFixed(toFixed);
  }
  return '';
};

const CopyTool = ({ text, label }: any) => {
  const [copied, setCopied] = useState("");
  return (
    <>
      <Tooltip title={copied === text ? "Copied!" : `Copy ${label}`} placement="top">
        <IconButton
          onClick={(e: any) => {
            e.stopPropagation();
            navigator.clipboard.writeText(text);
            setCopied(text);
            setTimeout(() => setCopied(''), 3000);
          }}
        >
          <img src={CopyIcon} alt="copy-icon" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const TradesTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.overflow}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>Wallet</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Sell</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Buy</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Transaction</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Updated</b></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}              
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell className={classes.cell}>
                <div className={classes.label}>{row.operation}</div>
                <div className={classes.address}>{shrinkAddress(row.walletAddress)}</div>
              </TableCell>
              <TableCell className={classes.cell}>

                <Grid container spacing={0} alignItems='center' >
                  <Grid item style={{ textAlign: 'right' }}><IconBadgeSml mainIcon={row.fromIcon ? row.fromIcon : CoinIcon} /></Grid>
                  <Grid item style={{ textAlign: 'left' }}>
                    <div className={classes.amount}><span>{numToFixed(row.fromAmount, 4)}</span> {row.fromSymbol}</div>
                    <div className={classes.address}>{shrinkAddress(row.fromAddress)}</div>
                  </Grid>
                </Grid>

              </TableCell>
              <TableCell className={classes.cell} align="center">

                <Grid container spacing={0} alignItems='center' >
                  <Grid item style={{ textAlign: 'right' }}><IconBadgeSml mainIcon={row.toIcon ? row.toIcon : CoinIcon} /></Grid>
                  <Grid item style={{ textAlign: 'left' }}>
                    <div className={classes.amount}><span>{numToFixed(row.toAmount, 4)}</span> {row.toSymbol}</div>
                    <div className={classes.address}>{shrinkAddress(row.toAddress)}</div>
                  </Grid>
                </Grid>

              </TableCell>
              <TableCell className={classes.cell} align="left">

                <Grid container spacing={0} alignItems='center' >
                  <Grid item style={{ textAlign: 'left' }}>
                    <div className={classes.label}>{row.state}</div>
                    <div className={classes.address}>{shrinkAddress(row.txnHash)}</div>
                  </Grid>
                  <Grid item style={{ textAlign: 'left' }}>
                    <CopyTool text={row.txnHash} label={'Txn Hash'} />
                  </Grid>
                </Grid>

              </TableCell>
              <TableCell className={classes.cell} align="center">
                <div className={classes.label}>{format(new Date(row.updatedAt), 'dd-MMM-yyyy, H:mm a')}</div>
                <div className={classes.address}>created: {format(new Date(row.createdAt), 'dd-MMM, H:mm a')}</div>
              </TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
