import { useApiNFTs } from '../../../../hooks/prime/useApiNFTs';

import { NFTTable } from './NFTTable';

export const PrimeNFTs = (props: any) => {
  const { userId } = props;
  const { data, isLoading, mutate } = useApiNFTs(userId);

  return (
    <>
      {data && (
        <NFTTable data={data} isLoading={isLoading} mutate={mutate} />
      )}
    </>
  );
}