import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(1, 3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontWeight: 500
  },
  total: {
    color: 'rgba(0, 0, 0, 0.6)',
  },  
}));

export const GrandTotal = (props: any) => {
  const { totalBalanceUSD } = props;
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item textAlign='left'><span className={classes.title}>Grand Total</span></Grid>
        <Grid item textAlign='right'><span className={classes.total}>{totalBalanceUSD.toFixed(4)}</span></Grid>
      </Grid>      
    </Paper>
  );
}