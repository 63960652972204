import { api } from './api-cfx';

const request = api('balance');

export const getBalancePortfolio = async () => {
  return await request('portfolio');
};

export const refreshBalancePortfolio = async () => {
  return await request('refresh', 'post');
};

export const refreshBalancePoll = async () => {
  return await request('refresh');
};

export const WaitForBalance = async () => {
  const dt = new Date().getTime();
  while (true) {
    const data = await refreshBalancePoll();
    if (data && (data.state === "COMPLETED" || data.state === "FAILED")) {
      break;
    }
    await new Promise(f => setTimeout(f, 1000));
    const now = new Date().getTime();
    if (now - dt > 30000) {
      break;
    }
  }
  return true;
}