import { useState, useEffect } from 'react';
import { createAddress, updateAddress } from '../../../services/prime-admin';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const initialSource = { name: '', networks: '', minutes: '' };

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%'
  },
  cell: {
    color: theme.palette.text.secondary,
    lineHeight: '1rem',
    padding: theme.spacing(1, 0.5)
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditAddressModal = (props: any) => {
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [sources, setSources] = useState<any[]>([Object.assign({}, initialSource)]);
  const [status, setStatus] = useState(true);

  const [errorAddress, setErrorAddress] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.selectedAddress) {
      setAddress(props.selectedAddress.address);
      setDescription(props.selectedAddress.description);
      setSources(props.selectedAddress.sources || [Object.assign({}, initialSource)]);
      setStatus(props.selectedAddress.status);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.selectedAddress])

  const clearForm = () => {
    setAddress('');
    setDescription('');
    setSources([Object.assign({}, initialSource)]);
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorAddress('');
    setErrorDescription('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (address?.length === 0) {
      setErrorAddress('Enter the wallet address.');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const setSourceName = (index: number, val: string) => {
    setSources(ss => ss.map((s: any, i: number) => i === index ? Object.assign({}, s, { name: val.trim() }) : s));
  };

  const setSourceNetworks = (index: number, val: string) => {
    setSources(ss => ss.map((s: any, i: number) => i === index ? Object.assign({}, s, { networks: val.trim() }) : s));
  };

  const setSourceMinutes = (index: number, val: string) => {
    const n = Number(val);
    if (!isNaN(n)) {
      setSources(ss => ss.map((s: any, i: number) => i === index ? Object.assign({}, s, { minutes: n }) : s));
    }
  };

  const handleAddSource = () => {
    setSources(s => [...s, Object.assign({}, initialSource)]);
  }

  const handleDeleteSource = (index: number) => {
    setSources(ss => ss.filter((s: any, i: number) => i !== index));
  }

  const cleanSources = (ss: any[]) => {
    return ss.filter((s: any) => s.name);
  }

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.selectedAddress) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating address configuration: <strong>${address}</strong> ... ` });
    createAddress(address, description, cleanSources(sources), status).then((data) => {
      console.log('CreateAddress', data);
      if (data) {
        setMessage({ type: 'success', description: `Address Configuration: <strong>${address}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit(address);
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating address configuration: <strong>${address}</strong> ... ` });
    updateAddress(props.selectedAddress?.address, description, cleanSources(sources), status).then((data) => {
      console.log('UpdateAddress', data);
      if (data) {
        setMessage({ type: 'success', description: `Address Configuration: <strong>${address}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(address);
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.selectedAddress && (
        <DialogTitle id='form-dialog-title'>Update Address Configuration</DialogTitle>
      )}
      {!props.selectedAddress && (
        <DialogTitle id='form-dialog-title'>Create a new Address Configuration</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.selectedAddress && (
          <DialogContentText>Update the configuration details:</DialogContentText>
        )}
        {!props.selectedAddress && (
          <DialogContentText>Input the configuration details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField
            id='address'
            label='address'
            value={address}
            error={errorAddress.length > 0}
            helperText={errorAddress}
            onChange={(e: any) => setAddress(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          <TextField
            id='description'
            label='description'
            value={description}
            error={errorDescription.length > 0}
            helperText={errorDescription}
            onChange={(e: any) => setDescription(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell} align="left"><b>Source</b></TableCell>
                <TableCell className={classes.cell} align="left"><b>Networks</b></TableCell>
                <TableCell className={classes.cell} align="left"><b>Minutes</b></TableCell>
                <TableCell className={classes.cell} align="right"><IconButton color="primary" onClick={() => handleAddSource()}><AddIcon /></IconButton></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sources.map((source: any, index: number) => (
                <TableRow key={index} component="tr">
                  <TableCell className={classes.cell} align="left">
                    <TextField
                      id={`source-${index}-name`}
                      label={`source ${index + 1}`}
                      value={source.name}
                      onChange={(e: any) => setSourceName(index, e.target.value.trim())}
                      margin='normal'
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    <TextField
                      id={`source-${index}-networks`}
                      label={`networks`}
                      value={source.networks}
                      onChange={(e: any) => setSourceNetworks(index, e.target.value.trim())}
                      margin='normal'
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    <TextField
                      id={`source-${index}-minutes`}
                      label={`minutes`}
                      value={source.minutes}
                      onChange={(e: any) => setSourceMinutes(index, e.target.value.trim())}
                      margin='normal'
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    <IconButton onClick={() => handleDeleteSource(index)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e: any, val: any) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditAddressModal;