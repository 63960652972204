import { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import { EditGroupUserModal } from './EditGroupUserModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const UserTable = (props: any) => {
  const { data, isLoading, mutate, selectedGroup, selectUser, selectedUser } = props;
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const navigate = useNavigate();


  const handleAddNewGroup = () => {
    console.log('handleAddNewGroup');
    selectUser();
    setOpenEditGroupModal(true);
  };

  const handleOpenEditGroupModal = (id: string) => {
    console.log('handleOpenEditGroupModal', id);
    selectUser(id);
    setOpenEditGroupModal(true);
  };

  const submitEditGroup = (groupId: string, userId: string) => {
    console.log('submitEditGroup', groupId, userId);
    setOpenEditGroupModal(false);
    mutate();
  };

  const closeEditGroupModal = () => {
    console.log('closeEditGroup');
    setOpenEditGroupModal(false);
  };

  const handleUser = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>ID</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Email</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Updated</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              <IconButton color="primary" onClick={() => handleAddNewGroup()}><AddIcon /></IconButton>
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((row: any) => (
            <TableRow component="tr" key={row.userId} className={classes.row}>
              <TableCell className={classes.cell} align="center">{row.userId}</TableCell>
              <TableCell className={classes.cell} align="left"><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.email}</TableCell>
              <TableCell className={classes.cell} align="left">{format(new Date(row.updatedAt), 'dd-MMM-yyyy HH:mm')}</TableCell>
              <TableCell className={classes.cell} align="right">
                <IconButton color="primary" onClick={() => handleOpenEditGroupModal(row.userId)}><GroupIcon /></IconButton>
                <IconButton color="primary" onClick={() => handleUser(row.userId)}><LaunchIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditGroupUserModal selectedGroup={selectedGroup} selectedUser={selectedUser} open={openEditGroupModal} closeModal={closeEditGroupModal} handleSubmit={submitEditGroup} />
    </>
  );
};
