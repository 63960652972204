import useSWR from 'swr';
import { getBalance } from "../../services/prime-wallets";
import { useAuthStore } from "../context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiBalance = ([_, userId]: [string, string]) => getBalance(userId);

export const useApiBalance = (userId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && userId ? ['apiBalance', userId] : null, apiBalance, options);
  return {
    data: userId && data ? data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};
