import clsx from "clsx";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

export interface MenuItemData {
  icon: string;
  name: string;
  onClick?: any;
  isActive?: (value: boolean) => void;
  className?: string;
}

export interface MenuItemProps {
  menuItem: MenuItemData;
  id?: string;
  className?: string;
  openSideBar?: boolean;
  isDrawerOpen?: boolean;
}

interface IMenuItemStylesProps {
  isDrawerOpen?: boolean;
}


const useStyles = makeStyles<IMenuItemStylesProps>()((_theme, props) =>
({
  listItem: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: (props.isDrawerOpen ? '0 30px' : 0),
    borderRadius: 4,
    marginBottom: 10,
    '&:hover': {
      background: '#F37222',
      borderRadius: 4,
      '& span': {
        color: '#fff',
      },
    },
  },
  isActive: {
    background: '#F37222',
    '& span': {
      color: '#fff',
    },
  },
  listText: {
    display: (props.isDrawerOpen ? 'flex' : 'none'),
    color: '#C2C1C6',
  },
  listIcon: {
    minWidth: (props.isDrawerOpen ? 41 : 25),
  },
}));

export function LogoutItem(props: MenuItemProps) {
  const { isDrawerOpen } = props;
  const { classes } = useStyles({ isDrawerOpen });

  const handleClick = (event: any) => {
    event.preventDefault();
    if (props.menuItem.onClick) {
      props.menuItem.onClick(event);
    }
  };

  return (
    <ListItem button className={clsx(classes.listItem)} onClick={handleClick}>
      <ListItemIcon className={classes.listIcon}>
        <img src={props.menuItem.icon} alt={""} />
      </ListItemIcon>
      <ListItemText primary={props.menuItem.name} className={classes.listText} />
    </ListItem>
  );
}
