import Acala from '../assets/networks/acala.png';
import Algorand from '../assets/networks/algorand.png';
import Arbitrum from '../assets/networks/arbitrum.png';
import Avalanche from '../assets/networks/avalanche.png';
import Binance from '../assets/networks/binance.png';
import BinanceEx from '../assets/networks/binance-ex.png';
import Bitcoin from '../assets/networks/bitcoin.png';
import Centrifuge from '../assets/networks/centrifuge.png';
import Connect from '../assets/networks/connect.png';
import Cosmos from '../assets/networks/cosmos.png';
import Deribit from '../assets/networks/deribit.png';
import Ethereum from '../assets/networks/ethereum.png';
import Fantom from '../assets/networks/fantom.png';
import Fiat from '../assets/networks/fiat.png';
import Filecoin from '../assets/networks/filecoin.png';
import Fireblocks from '../assets/networks/fireblocks.png';
import FTX from '../assets/networks/ftx.png';
import Interlay from '../assets/networks/interlay.png';
import Kraken from '../assets/networks/kraken.png';
import Kusama from '../assets/networks/kusama.png';
import Polygon from '../assets/networks/matic.png';
import Moonbeam from '../assets/networks/moonbeam.png';
import Offchain from '../assets/networks/offchain.png';
import Optimism from '../assets/networks/optimism.png';
import Parallel from '../assets/networks/parallel.png';
import Polkadot from '../assets/networks/polkadot.png';
import Ripple from '../assets/networks/ripple.png';
import Ronin from '../assets/networks/ronin.png';
import Solana from '../assets/networks/solana.png';
import SolanaBeach from '../assets/networks/solanabeach.png';
import Terra from '../assets/networks/terra.png';
import Tron from '../assets/networks/tron.png';
import ZKSync from '../assets/networks/zksync.png';

interface INotify {
  message: string;
  status?: string;
}

interface IWalletImage {
  [name: string]: string;
}

const walletImages: IWalletImage = {
  acala: Acala,
  algorand: Algorand,
  arbitrum: Arbitrum,
  avalanche: Avalanche,
  binance: BinanceEx,
  bitcoin: Bitcoin,
  bsc: Binance,
  centrifuge: Centrifuge,
  connect: Connect,
  cosmos: Cosmos,
  deribit: Deribit,
  ethereum: Ethereum,
  ftx: FTX,
  filecoin: Filecoin,
  fantom: Fantom,
  fiat: Fiat,
  fireblocks: Fireblocks,
  interlay: Interlay,
  kraken: Kraken,
  kusama: Kusama,
  moonbeam: Moonbeam,
  offchain: Offchain,
  optimism: Optimism,
  other: Ethereum,
  parallel: Parallel,
  polkadot: Polkadot,
  polygon: Polygon,
  solana: Solana,
  solanabeach: SolanaBeach,
  ripple: Ripple,
  ronin: Ronin,
  terra: Terra,
  tron: Tron,
  zksync: ZKSync,
};

interface INetworkName {
  [name: string]: string;
}

const networkNames: INetworkName = {
  acala: 'Acala',
  algorand: 'Algorand',
  arbitrum: 'Arbitrum',
  avalanche: 'Avalanche',
  binance: 'Binance',
  bitcoin: 'Bitcoin',
  bsc: 'BSC',
  centrifuge: 'Centrifuge',
  connect: 'Connect',
  cosmos: 'Cosmos',
  deribit: 'Deribit',
  ethereum: 'Ethereum',
  fantom: 'Fantom',
  fiat: 'Fiat',
  filecoin: 'Filecoin',
  fireblocks: 'Fireblocks',
  ftx: 'FTX',
  interlay: 'Interlay',
  kraken: 'Kraken',
  kusama: 'Kusama',
  moonbeam: 'Moonbeam',
  offchain: 'Offchain',
  optimism: 'Optimism',
  parallel: 'Parallel',
  polkadot: 'Polkadot',
  polygon: 'Polygon',
  solana: 'Solana',
  solanabeach: 'Solana Beach',
  ripple: 'Ripple',
  ronin: 'Ronin',
  terra: 'Terra',
  tron: 'Tron',
  zksync: 'ZKSync',
};

interface INetworkTotal {
  [name: string]: number;
}

const networkTotals: INetworkTotal = {
  acala: 0,
  algorand: 0,
  arbitrum: 0,
  avalanche: 0,
  binance: 0,
  bitcoin: 0,
  bsc: 0,
  centrifuge: 0,
  connect: 0,
  cosmos: 0,
  deribit: 0,
  ethereum: 0,
  fantom: 0,
  fiat: 0,
  filecoin: 0,
  fireblocks: 0,
  ftx: 0,
  interlay: 0,
  kraken: 0,
  kusama: 0,
  moonbeam: 0,
  offchain: 0,
  optimism: 0,
  parallel: 0,
  polkadot: 0,
  polygon: 0,
  solana: 0,
  solanabeach: 0,
  ripple: 0,
  ronin: 0,
  terra: 0,
  tron: 0,
  zksync: 0,
};

interface INetworkId {
  [name: string]: string;
}

export const networkIds: INetworkId = {
  '1': 'ethereum',
  '10': 'optimism',
  '56': 'bsc',
  '137': 'polygon',
  '250': 'fantom',
  '1284': 'moonbeam',
  '2020': 'ronin',
  '42161': 'arbitrum',
  '43114': 'avalanche',
  '1399811149': 'solana',
};

interface INetworkTxnLink {
  [name: string]: string;
}
export const networkTxnLinks: INetworkTxnLink = {
  'bitcoin': 'https://www.blockchain.com/explorer/transactions/btc/',
  'ethereum': 'https://etherscan.io/tx/',
  'optimism': 'https://optimistic.etherscan.io/tx/',
  'bsc': 'https://bscscan.com/tx/',
  'polygon': 'https://polygonscan.com/tx/',
  'fantom': 'https://ftmscan.com/tx/',
  'moonbeam': 'https://moonscan.io/tx/',
  'ronin': 'https://explorer.roninchain.com/tx/',
  'arbitrum': 'https://arbiscan.io/tx/',
  'avalanche': 'https://avascan.info/blockchain/c/tx/',
  'solana': 'https://explorer.solana.com/tx/',
};
  export const getWalletImage = (value: string) => {
    const img = walletImages[value];
    if (img) return img;
    return walletImages['other'];
  }

  export const getNetworkName = (value: string) => {
    const name = networkNames[value];
    if (name) return name;
    return value;
  }

  export const getNetworkFromId = (value: number) => {
    // console.log('getNetworkFromId', value, networkIds[value.toString()]);
    return networkIds[value.toString()];
  }

  export const getNetworkTotals = () => {
    return Object.assign({}, networkTotals);
  }

  export const getNetworkTxnLink = (value: string) => {
    const link = networkTxnLinks[value];
    if (link) return link;
    return '';
  }
