import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Home } from "./pages/Home/Home";
import { SignIn } from "./pages/SignIn/SignIn";
import { Users } from "./pages/Users/Users";
import { User } from "./pages/User/User";
import { Transactions } from "./pages/Transactions/Transactions";
import { AddressConfig } from "./pages/AddressConfig/AddressConfig";
import { TokenConfig } from "./pages/TokenConfig/TokenConfig";
import { FeeConfig } from "./pages/FeeConfig/FeeConfig";
import { FireblocksConfig } from "./pages/FireblocksConfig/FireblocksConfig";
import { OffchainConfig } from "./pages/OffchainConfig/OffchainConfig";

import { Settings } from "./pages/Settings/Settings";

export const Router = () => {
  return (
    <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/signin"} element={<SignIn />} />
          <Route path={"/users"} element={<Users />} />
          <Route path={"/user/:userId"} element={<User />} />
          <Route path={"/transactions"} element={<Transactions />} />
          <Route path={"/addresses"} element={<AddressConfig />} />
          <Route path={"/tokens"} element={<TokenConfig />} />
          <Route path={"/fees"} element={<FeeConfig />} />
          <Route path={"/fireblocks"} element={<FireblocksConfig />} />
          <Route path={"/offchain"} element={<OffchainConfig />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
  );
};
