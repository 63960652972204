import { useApiTradeList } from "../../../../hooks/cfx/useApiTradeList";
import { TransactionTable } from './TransactionTable';

export const CFXTransactions = () => {
  const { data, isLoading, mutate } = useApiTradeList('finished', '2000-01-01T00:00:00Z', '');
  return (
    <>
      {data && (
        <TransactionTable data={data} isLoading={isLoading} mutate={mutate} />
      )}
    </>
  );
}