import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { PrimeTrades } from './tabs/PrimeTrades/PrimeTrades';
import { ConnectTransactions } from './tabs/ConnectTransactions/ConnectTransactions';
import { CFXTransactions } from './tabs/CFXTransactions/CFXTransactions';

import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`transaction-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

export const TransactionTabs = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log('handleChange', newValue);
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar color="secondary" position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab id={`transaction-tabs-0`} label="Prime Trades" />
          <Tab id={`transaction-tabs-1`} label="Connect Transactions" />
          <Tab id={`transaction-tabs-2`} label="CFX Transactions" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={(index: number) => setValue(index)}
      >
        <TabPanel value={value} index={0} dir={theme.direction}><PrimeTrades /></TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}><ConnectTransactions /></TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}><CFXTransactions /></TabPanel>
      </SwipeableViews>
    </Box>
  );
}