import { api } from "./api-cfx";

const request = api("trade");

// Trade Offers
export const listTradeOffers = async () => {
  return await request('offer');
};

export const getTradeOffer = async (offerId: string) => {
  return await request(`offer`, 'get', { offerId });
};

export const createTradeOffer = async (symbolSend: string, symbolRecv: string, amount: string, rate: string, expireAt: string, status: boolean) => {
  return await request(`offer`, 'post', undefined, { symbolSend, symbolRecv, amount, rate, expireAt, status });
};

export const updateTradeOffer = async (offerId: string, symbolSend: string, symbolRecv: string, amount: string, rate: string, expireAt: string, status: boolean) => {
  return await request(`offer/${offerId}`, 'put', undefined, { symbolSend, symbolRecv, amount, rate, expireAt, status });
};

export const deleteTradeOffer = async (offerId: string) => {
  return await request(`offer/${offerId}`, 'delete');
};

export const listOffers = async (type: string, from: string) => {
  return await request(`offers/${type}`, 'get', { from });
};


// Trade FX
export const listTradeFX = async () => {
  return await request('fx');
};

export const getTradeFX = async (tradeId: string) => {
  console.log('getTradeFX', tradeId);
  return await request(`fx/${tradeId}`);
};

export const confirmTrade = async (offerId: string) => {
  return await request('confirm', 'post', {}, { offerId });
};

export const executeTrade = async (tradeId: string) => {
  return await request('execute', 'post', {}, { tradeId });
};

export const cancelTrade = async (tradeId: string) => {
  return await request('cancel', 'post', {}, { tradeId });
};

export const listTrades = async (type: string, from: string, userId: string) => {
  return await request(`list/${type}`, 'get', { from, userId });
};

// Trade Stats
export const getTradeStats = async () => {
  console.log('getTradeStats');
  return await request(`stats`);
};

export enum TradeState { 
  created = 'CREATED', 
  confirmed = 'CONFIRMED', 
  completed = 'COMPLETED', 
  cancelled = 'CANCELLED', 
  failed = 'FAILED', 

  // L1 Txn States
  waitL2Auth = 'WAIT_L2_TXN_AUTH', 
  execL1Txn = 'EXEC_L1_TXN', 
  waitL1Txn = 'WAIT_L1_TXN', 
  failL1Txn = 'FAIL_L1_TXN', 
  waitL2Capt = 'WAIT_L2_TXN_CAPTURE', 
  enqueueL1Balance = 'ENQUEUE_L1_BALANCE',
  waitL1Balance = 'WAIT_L1_BALANCE',

  // L2 Txn States
  execL2Auth = 'EXEC_L2_TXN_AUTH', 
  failL2Auth = 'FAIL_L2_TXN_AUTH', 
  execL2Capt = 'EXEC_L2_TXN_CAPTURE', 
  enqueueL2Balance = 'ENQUEUE_L2_BALANCE',
  waitL2Balance = 'WAIT_L2_BALANCE',
};