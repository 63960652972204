import { useState } from 'react';
import { useApiUserGroups } from "../../../hooks/prime/useApiUserGroups";

import { GroupTable } from "./GroupTable";
import { CanvasSkeleton } from "../../../shared/UI/CanvasSkeleton";

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2.5, 3, 3, 3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const Groups = (props: any) => {
  const { userId } = props;
  const [selectedGroupUser, setSelectedGroupUser] = useState<any>();
  const { data, isLoading, mutate } = useApiUserGroups(userId);
  
  const selectGroupUser = (id?: string) => {
    const groupUser = data?.find((x: any) => x.groupId === id);
    setSelectedGroupUser(groupUser);
  }

  const { classes } = useStyles();
  return (
    <>
      {(!data) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {data && (
        <Paper className={classes.paper}>
          <h2 className={classes.title}>Group Membership</h2>
          <GroupTable data={data} mutate={mutate} isLoading={isLoading} selectGroupUser={selectGroupUser} selectedGroupUser={selectedGroupUser} />
        </Paper>
      )}
    </>
  );
}
