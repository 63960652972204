import { format } from 'date-fns';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface ITradeMessages {
  trade?: any;
  height?: string;
}

const useStyles = makeStyles<ITradeMessages>()((theme: any, props: ITradeMessages) => ({
  root: {
    marginTop: theme.spacing(1),
    height: props.height ?? '250px',
    overflowY: 'scroll',
    backgroundColor: '#fafafa',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1)
  },
  date: {
    fontSize: '0.75rem',
    fontWeight: 300,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.25, 0, 0.25, 0),
    borderBottom: 'none'
  },
  time: {
    fontSize: '0.75rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.25, 2, 0.25, 2),
    borderBottom: 'none'
  },
  message: {
    fontSize: '0.75rem',
    fontWeight: 300,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderStyle: 'border-box',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.25, 0, 0.25, 0),
    borderBottom: 'none'
  }
}));

export const TradeMessages = (props: ITradeMessages) => {
  const { classes } = useStyles(props);
  return (
    <div className={classes.root}>
      <Table>
        <TableBody>
          {props.trade?.logs?.map((log: any) => (
            <TableRow component='tr'>
              <TableCell className={classes.date} align='center'>{format(new Date(log.loggedAt), 'dd-MMM-yyyy')}</TableCell>
              <TableCell className={classes.time} align='center'>{format(new Date(log.loggedAt), 'HH:mm:ss')}</TableCell>
              <TableCell className={classes.message} align='left'>{log.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}