import useSWR from 'swr';
import { getUserTranactions } from "../../services/prime-trade";
import { useAuthStore } from "../context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiTradeTransactions = ([_, userId]: [string, string]) => getUserTranactions(userId);

export const useApiTradeTransactions = (userId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiTradeTransactions', userId] : null, apiTradeTransactions, options);
  return {
    data: userId && data && data.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};
