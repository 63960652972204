import { format } from "date-fns";

import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import BlockIcon from '@mui/icons-material/Block';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginTop: theme.spacing(1)
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  icon: {
    fontSize: '18px',
  },
  approved: {
    color: theme.palette.success.dark,
  },
  locked: {
    color: theme.palette.error.dark,
  },

}));

export const UserDetails = (props: any) => {
  const { data } = props;
  const { classes } = useStyles();
  return (
    <Grid container alignItems="center" spacing={1} className={classes.grid}>
      <Grid item xs={5} className={classes.itemTitle} style={{ marginBottom: '3px' }}>username: </Grid><Grid item xs={7} className={classes.itemValue}><b>{data.username}</b></Grid>
      <Grid item xs={5} className={classes.itemTitle}>name: </Grid><Grid item xs={7} className={classes.itemValue}>{data.name}</Grid>
      <Grid item xs={5} className={classes.itemTitle}>email: </Grid><Grid item xs={7} className={classes.itemValue}>{data.email}</Grid>
      <Grid item xs={5} className={classes.itemTitle}>last updated: </Grid><Grid item xs={7} className={classes.itemValue}>{format(new Date(data.updatedAt), 'dd-MMM-yyyy h:mm a')}</Grid>
      <Grid item xs={5} className={classes.itemTitle} style={{ marginBottom: '6px' }}>status: </Grid><Grid item xs={7} className={classes.itemValue}>
        {(data.approved) && (
          <div className={classes.approved}><CheckCircleOutlineRoundedIcon className={classes.icon} /></div>
        )}
        {(!data.approved) && (
          <div className={classes.locked}><BlockIcon className={classes.icon} /></div>
        )}
      </Grid>
    </Grid>
  );
}