import { useEffect, useRef, useState } from "react";

import { shrinkNumber} from '../../../../helpers/shrink';

import { NFTModal } from './NFTModal';

import { makeStyles } from 'tss-react/mui';
import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Grid, Typography } from "@mui/material";

const DEFAULT_MEDIA_URL = 'https://assets.prod.cloud.canvas.co/nft/nft-icon.svg';

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: 381,
    minWidth: 320,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
    },
  },
  actionArea: {
    position: "relative",
  },
  assetsIndicator: {
    background: "#fff",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    aligndatas: "center",
  },
  nftFolderIcon: {
    height: 14,
    width: 14,
    marginLeft: 6,
  },
  data: {
    display: "flex",
    justifyContent: "center",
  },
  imageloaderWrapper: {
    width: "100%",
    height: '250px',
    display: "flex",
    justifyContent: "center",
    aligndatas: "center",
  },
  cardMedia: {
  },
  mediaWrapper: {
    background: "#666",
  },
  cardContent: {
    display: "flex",
    padding: theme.spacing(2, 4),
    background: "#fff",
    justifyContent: "space-between",
    boxShadow: "0 4px 1px -3px #b3b1ad",
    //   boxShadow: '0px 4px 20px -5px rgba(0, 0, 0, 0.1)',
  },
  name: {
    fontWeight: 700,
  },
  balance: {
    color: theme.palette.primary.main,
  },


}));

export const NFTItem = ({ data }: any) => {
  const [openNFTModal, setOpenNFTModal] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null)!;
  const [imgLoading, setImgLoading] = useState(true);

  const handleOpenModal = () => {
    setOpenNFTModal(true);
  };

  const closeNFTModal = () => {
    setOpenNFTModal(false);
  };

  const getMediaUrl = (url: string) => url ? url.replace('ipfs://', 'https://ipfs.io/ipfs/') : '';

  useEffect(() => {
    const loadHandler = () => setImgLoading(false);
    const errHandler = () => {
      setImgLoading(false);
      const img = imageRef?.current;
      if (img) {
        img.src = DEFAULT_MEDIA_URL;
      }
    }
    const img = imageRef?.current;
    if (img) {
      img.addEventListener("load", loadHandler);
      img.addEventListener("error", errHandler);
    }
    return () => {
      if (img) {
        img.removeEventListener("load", loadHandler);
        img.removeEventListener("error", errHandler);
      }
    };
  }, [imageRef]);

  const { classes } = useStyles();
  return (
    <>
      <Card className={classes.root} onClick={handleOpenModal}>
        <CardActionArea className={classes.actionArea}>
          <div className={classes.mediaWrapper}>
            {imgLoading && (
              <div className={classes.imageloaderWrapper}>
                <CircularProgress />
              </div>
            )}
            <CardMedia
              ref={imageRef}
              component="img"
              className={classes.cardMedia}
              style={{ display: imgLoading ? "none" : "block" }}
              width='100%'
              height='250px'
              image={getMediaUrl(data.mediaUrl)}
              title={data.name}
              alt={data.name}
            />
          </div>
          <CardContent className={classes.cardContent}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" className={classes.name}>{data.name}</Typography>
              </Grid>
              {data.balanceUSD !== 0 && (
                <Grid item>
                  <Typography variant="h6" className={classes.balance}>{shrinkNumber(data.balanceUSD)}</Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      <NFTModal data={data} open={openNFTModal} closeModal={closeNFTModal} />

    </>
  );
};
