import { api } from "./api-connect";

const request = api('fees_management');

export const getTransactionUsers = async () => {
  return await request(`/get_transactions_users`);
}

export const getBalance = async (starkkey: string) => {
  return await request(`/get_balance?stark_key=${starkkey}`);
}

export const getFeePolicies = async () => {
  return await request('fee_policy');
}

export const getFeePolicy = async (name: string) => {
  return await request(`fee_policy/${name}`);
}

export const createFeePolicy = async (name: string, description: string, operationType: string, assetId: string, ratioDividend: string, ratioDivisor: string, minAmount: string, ccu: string) => {
  return await request(`fee_policy`, 'post', {}, { policy: name, description, operationType, defaultFee: { asset_id: assetId, ratio: { dividend: Number(ratioDividend), divisor: Number(ratioDivisor)}, min_amount: Number(minAmount), ccu: Number(ccu) } });
}

export const updateFeePolicy = async (name: string, description: string, operationType: string, assetId: string, ratioDividend: string, ratioDivisor: string, minAmount: string, ccu: string) => {
  return await request(`fee_policy`, 'put', {}, { policy: name, description, operationType, defaultFee: { asset_id: assetId, ratio: { dividend: ratioDividend, divisor: ratioDivisor}, minAmount, ccu } });
}

export const deleteFeePolicy = async (name: string) => {
  return await request(`fee_policy/${name}`, 'delete');
}