import { useState, useEffect } from 'react';
import { createVault, updateVault } from '../../../../services/prime-admin';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditVaultModal = (props: any) => {
  const [vault, setVault] = useState('');
  const [whitelistId, setWhitelistId] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(false);

  const [errorVault, setErrorVault] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.vault) {
      setVault(props.vault.vault || '');
      setWhitelistId(props.vault.whiteListId || '');
      setDescription(props.vault.description || '');
      setStatus(props.vault.status === true);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.vault])

  const clearForm = () => {
    setVault('');
    setWhitelistId('');
    setDescription('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorVault('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (vault?.length === 0 || isNaN(Number(vault))) {
      setErrorVault('Enter the vault number.');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.vault) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating vault: <strong>${vault}</strong> ... ` });
    createVault(props.secretId, vault.trim(), whitelistId.trim(), description.trim(), (status === true)).then((data) => {
      console.log('createVault', data);
      if (data) {
        setMessage({ type: 'success', description: `Vault: <strong>${vault}</strong>, created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating vault details: <strong>${vault}</strong> ... ` });
    updateVault(props.vault.secret, props.vault.vaultId, vault.trim(), whitelistId.trim(), description.trim(), (status === true)).then((data) => {
      console.log('UpdateVault', data);
      if (data) {
        setMessage({ type: 'success', description: `Vault: <strong>${vault}</strong>, updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.vault && (
        <DialogTitle id='form-dialog-title'>Update Fireblocks Vault</DialogTitle>
      )}
      {!props.vault && (
        <DialogTitle id='form-dialog-title'>Create a new Fireblocks Vault</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.vault && (
          <DialogContentText>Update the vault details:</DialogContentText>
        )}
        {!props.vault && (
          <DialogContentText>Input the vault details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm={8}>
              <TextField
                id='secretId'
                label='Fireblocks Secret'
                value={props.secretId}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                id='vault'
                label='Vault Number'
                value={vault}
                error={errorVault.length > 0}
                helperText={errorVault}
                onChange={e => setVault(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
          <TextField
            id='whitelistId'
            label='Whitelist Id'
            value={whitelistId}
            onChange={e => setWhitelistId(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
            autoFocus
          />
          <TextField
            id='description'
            label='Description'
            value={description}
            onChange={e => setDescription(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
            autoFocus
          />
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e, val) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert as any}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditVaultModal;