import { useState, useEffect } from 'react';
import { createSecret, updateSecret, patchSecret } from "../../../../services/prime-admin";
import { useApiGroupList } from '../../../../hooks/prime/useApiGroupList';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const getNameValue = (name: string) => {
  const titlecase = name.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1));
  const nospace = titlecase.replace(/[^a-zA-Z0-9]+/g, '-');
  const prefix = nospace.replace(/^[-]+$/g, '');
  const trailing = prefix.replace(/[-]+$/g, '');
  return trailing;
}

const getSecretValue = (apiKey: string, apiSecret: string) => {
  return JSON.stringify({ apiKey, privateKey: apiSecret });
};

const useStyles = makeStyles()(theme => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditSecretModal = (props: any) => {
  const { data: dataGroupList } = useApiGroupList();

  const [groupId, setGroupId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');

  const [errorGroup, setErrorGroup] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorApiKey, setErrorApiKey] = useState('');
  const [errorApiSecret, setErrorApiSecret] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.secret) {
      setGroupId(props.secret.groupId);
      setName(props.secret.name);
      setDescription(props.secret.description);
      setApiKey('');
      setApiSecret('');
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.secret])

  const clearForm = () => {
    setGroupId('');
    setName('');
    setDescription('');
    setApiKey('');
    setApiSecret('');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorGroup('');
    setErrorName('');
    setErrorDescription('');
    setErrorApiKey('');
    setErrorApiSecret('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (groupId?.length === 0) {
      setErrorGroup('Select the user group.');
      return false;
    }
    if (props.type === 'create') {
      if (name?.length === 0) {
        setErrorName('Enter the secret name.');
        return false;
      }
    }
    if (props.type === 'create' || props.type === 'patch') {
      if (apiKey?.length === 0) {
        setErrorApiKey('Enter the api key.');
        return false;
      }
      if (apiSecret?.length === 0) {
        setErrorApiSecret('Enter the api private secret.');
        return false;
      }
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      switch (props.type) {
        case 'create': return handleAddNew();
        case 'update': return handleUpdate();
        case 'patch': return handlePatch();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating secret: <strong>${name}</strong> ... ` });
    createSecret(groupId, getNameValue(name), description.trim(), getSecretValue(apiKey, apiSecret)).then((data) => {
      console.log('CreateSecret', data);
      if (data) {
        setMessage({ type: 'success', description: `Secret: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating secret details: <strong>${name}</strong> ... ` });
    updateSecret(props.secret?.secretId, groupId, description.trim()).then((data) => {
      console.log('UpdateSecret', data);
      if (data) {
        setMessage({ type: 'success', description: `Secret: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handlePatch = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating secret key: <strong>${name}</strong> ... ` });
    patchSecret(props.secret?.secretId, getSecretValue(apiKey, apiSecret)).then((data) => {
      console.log('PatchSecret', data);
      if (data) {
        setMessage({ type: 'success', description: `Secret Key: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <DialogTitle id='form-dialog-title'>Fireblocks API Secret</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        {(props.type === 'create') && (
          <DialogContentText>Input the secret details:</DialogContentText>
        )}
        {(props.type === 'update') && (
          <DialogContentText>Update the secret details:</DialogContentText>
        )}
        {(props.type === 'patch') && (
          <DialogContentText>Change the secret key:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          {(props.type === 'create' || props.type === 'update') && (
            <FormControl className={classes.formcontrol} fullWidth>
              <InputLabel id='groupId-label'>group</InputLabel>
              <Select
                labelId='groupId-label'
                id='groupId'
                label="group"
                value={groupId}
                onChange={(e: any) => setGroupId(e.target.value)}
                autoFocus
              >
                {dataGroupList?.map((group: any) => (
                  <MenuItem key={group.groupId} value={group.groupId}>{group.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{errorGroup}</FormHelperText>
            </FormControl>
          )}
          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={e => setName(e.target.value.trim())}
            disabled={props.type !== 'create'}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
            autoFocus
          />
          {(props.type === 'create' || props.type === 'update') && (
            <TextField
              id='description'
              label='description'
              value={description}
              error={errorDescription.length > 0}
              helperText={errorDescription}
              onChange={e => setDescription(e.target.value)}
              margin='normal'
              variant='outlined'
              InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
              fullWidth
            />
          )}
          {(props.type === 'create' || props.type === 'patch') && (
            <>
              <TextField
                id='apiKey'
                label='API key'
                value={apiKey}
                error={errorApiKey.length > 0}
                helperText={errorApiKey}
                onChange={e => setApiKey(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
              <TextField
                id='apiSecret'
                label='API Private Key'
                value={apiSecret}
                error={errorApiSecret.length > 0}
                helperText={errorApiSecret}
                onChange={e => setApiSecret(e.target.value)}
                margin='normal'
                variant='outlined'
                multiline
                minRows={10}
                maxRows={10}
                InputProps={{ style: { fontWeight: 300, fontSize: '0.8rem' } }}
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </>
          )}
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation disabled={isLoading}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditSecretModal;