import { useMemo } from 'react';
import { format, formatDistance, formatDuration, intervalToDuration } from 'date-fns';
import { useAuthStore } from "../../hooks/context/useAuthStore";
import { shrinkULID } from "../../helpers/shrink";

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%'
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
}));

export const TradeInfo = (props: any) => {
  const { currentUser } = useAuthStore();
  const { classes } = useStyles();
  const yourTransfer = useMemo(() => props.trade?.transfers?.find((x: any) => x.userId === currentUser.userId), [currentUser, props.trade]);
  const theirTransfer = useMemo(() => props.trade?.transfers?.find((x: any) => x.userId !== currentUser.userId), [currentUser, props.trade]);
  return (
    <>
      {currentUser && props.trade && props.trade.updatedAt && (
        <Grid container className={classes.root}>
          <Grid item xs={6}>
            <Grid container spacing={1} className={classes.grid}>
              <Grid item xs={5} className={classes.itemTitle}>Id</Grid><Grid item xs={7} className={classes.itemValue}>{shrinkULID(props.trade.tradeId)}</Grid>
              {(props.trade.offerUserId === currentUser.userId) && (
                <>
                  <Grid item xs={5} className={classes.itemTitle}>You Send</Grid><Grid item xs={7} className={classes.itemValue}>{Number(props.trade.amount).toFixed(2)} {props.trade.symbolSend}</Grid>
                  <Grid item xs={5} className={classes.itemTitle}>You Receive</Grid><Grid item xs={7} className={classes.itemValue}>{(Number(props.trade.amount) * Number(props.trade.rate)).toFixed(2)} {props.trade.symbolRecv}</Grid>
                </>
              )}
              {(props.trade.offerUserId !== currentUser.userId) && (
                <>
                  <Grid item xs={5} className={classes.itemTitle}>You Send</Grid><Grid item xs={7} className={classes.itemValue}>{(Number(props.trade.amount) * Number(props.trade.rate)).toFixed(2)} {props.trade.symbolRecv}</Grid>
                  <Grid item xs={5} className={classes.itemTitle}>You Receive</Grid><Grid item xs={7} className={classes.itemValue}>{Number(props.trade.amount).toFixed(2)} {props.trade.symbolSend}</Grid>
                </>
              )}
              <Grid item xs={5} className={classes.itemTitle}>Fee</Grid><Grid item xs={7} className={classes.itemValue}>{`${yourTransfer?.feeAmount.toFixed(4)} ${yourTransfer?.symbol} (${yourTransfer.feePercent}%)`}</Grid>
              <Grid item xs={5} className={classes.itemTitle}></Grid><Grid item xs={7} className={classes.itemValue}></Grid>
              <Grid item xs={5} className={classes.itemTitle}>Created</Grid><Grid item xs={7} className={classes.itemValue}>{format(new Date(props.trade.createdAt), "dd-MMM-yyyy HH:mm:ss")}</Grid>
              <Grid item xs={5} className={classes.itemTitle}>Updated</Grid><Grid item xs={7} className={classes.itemValue}>{format(new Date(props.trade.updatedAt), "dd-MMM-yyyy HH:mm:ss")}</Grid>
              <Grid item xs={5} className={classes.itemTitle}></Grid><Grid item xs={7} className={classes.itemValue}></Grid>
              <Grid item xs={5} className={classes.itemTitle}>Deal Duration</Grid><Grid item xs={7} className={classes.itemValue}>{formatDistance(new Date(props.trade.createdAt), new Date(props.trade.updatedAt))}</Grid>
              <Grid item xs={5} className={classes.itemTitle}>Transaction</Grid><Grid item xs={7} className={classes.itemValue}>{formatDuration(intervalToDuration({ start: (yourTransfer?.txnAuthorizedAt ? new Date(yourTransfer?.txnAuthorizedAt) : new Date(props.trade.createdAt)), end: (yourTransfer?.txnCapturedAt ? new Date(yourTransfer?.txnCapturedAt) : new Date(props.trade.updatedAt)) }))}</Grid>

            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} className={classes.grid}>
              <Grid item xs={5} className={classes.itemTitle}>Counterparty</Grid><Grid item xs={7} className={classes.itemValue}>{theirTransfer?.username}</Grid>
              <Grid item xs={5} className={classes.itemTitle}>Your Status</Grid><Grid item xs={7} className={classes.itemValue}>{yourTransfer?.state}</Grid>
              <Grid item xs={5} className={classes.itemTitle}>Their Status</Grid><Grid item xs={7} className={classes.itemValue}>{theirTransfer?.state}</Grid>
              <Grid item xs={5} className={classes.itemTitle}>Trade Status</Grid><Grid item xs={7} className={classes.itemValue}><b>{props.trade.state}</b></Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
