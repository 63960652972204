import { getWalletImage, getNetworkName } from "../../../../helpers/networks";

import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  title: {
    fontWeight: 500
  },
  total: {
    color: 'rgba(0, 0, 0, 0.6)',
  },  
}));

const Network = (props: any) => {
  const { network } = props;
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={2}><img src={getWalletImage(network.name)} alt={getNetworkName(network.name)} className={classes.image} /></Grid>
        <Grid item xs={5} textAlign='left'><span className={classes.title}>{getNetworkName(network.name)}</span></Grid>
        <Grid item xs={5} textAlign='right'><span className={classes.total}>{(network.balanceUSD.toFixed(4))}</span></Grid>
      </Grid>
    </Paper>
  );
}

export const Networks = (props: any) => {
  const { networks } = props;
  return (
    <>
      <h5>Networks</h5>
      <Grid container spacing={1}>
        {networks?.map((network: any) => (
          <Grid item xs={2} key={network.name}><Network network={network} /></Grid>
        ))}
      </Grid>
    </>
  );
}