import { useState, useEffect } from 'react';
import { createAsset, updateAsset } from "../../../../services/prime-admin";

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditWalletModal = (props: any) => {
  const [network, setNetwork] = useState('');
  const [category, setCategory] = useState('');
  const [protocol, setProtocol] = useState('');
  const [label, setLabel] = useState('');
  const [symbol, setSymbol] = useState('');
  const [decimals, setDecimals] = useState('');
  const [balance, setBalance] = useState('');
  const [price, setPrice] = useState('');
  const [priceId, setPriceId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [status, setStatus] = useState(false);

  const [errorNetwork, setErrorNetwork] = useState('');
  const [errorCategory, setErrorCategory] = useState('');
  const [errorProtocol, setErrorProtocol] = useState('');
  const [errorLabel, setErrorLabel] = useState('');
  const [errorSymbol, setErrorSymbol] = useState('');
  const [errorDecimals, setErrorDecimals] = useState('');
  const [errorBalance, setErrorBalance] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [errorPriceId, setErrorPriceId] = useState('');
  const [errorImageUrl, setErrorImageUrl] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.asset) {
      setNetwork(props.asset.network || '');
      setCategory(props.asset.category || '');
      setProtocol(props.asset.protocol || '');
      setLabel(props.asset.label || '');
      setSymbol(props.asset.symbol || '');
      setDecimals(props.asset.decimals || '');
      setBalance(props.asset.balance || '');
      setPrice(props.asset.price || '');
      setPriceId(props.asset.priceId || '');
      setImageUrl(props.asset.imageUrl || '');
      setStatus(props.asset.status === true);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.asset])

  const clearForm = () => {
    setNetwork('');
    setCategory('');
    setProtocol('');
    setLabel('');
    setSymbol('');
    setDecimals('');
    setBalance('');
    setPrice('');
    setPriceId('');
    setImageUrl('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorNetwork('');
    setErrorCategory('');
    setErrorProtocol('');
    setErrorLabel('');
    setErrorSymbol('');
    setErrorDecimals('');
    setErrorBalance('');
    setErrorPrice('');
    setErrorPriceId('');
    setErrorImageUrl('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (label?.length === 0) {
      setErrorLabel('Enter the asset label.');
      return false;
    }
    if (price?.length > 0 && isNaN(Number(price))) {
      setErrorPrice('Enter the price as a number.');
      return false;
    }
    if (priceId?.length > 0 && priceId.indexOf('coingecko:') < 0 && priceId.indexOf('fixer:') < 0) {
      setErrorPriceId('PriceId must begin with source. eg "coingecko:"');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.asset) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating asset: <strong>${label}</strong> ... ` });
    createAsset(props.walletId, network, category, protocol?.trim(), label?.trim(), symbol, decimals, balance, price, priceId, imageUrl, (status === true)).then((data) => {
      console.log('createAsset', data);
      if (data) {
        setMessage({ type: 'success', description: `Asset: <strong>${label}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating asset details: <strong>${label}</strong> ... ` });
    updateAsset(props.asset.walletId, props.asset.assetId, network, category, protocol?.trim(), label?.trim(), symbol, decimals, balance, price, priceId, imageUrl, (status === true)).then((data) => {
      console.log('UpdateAsset', data);
      if (data) {
        setMessage({ type: 'success', description: `Asset: <strong>${label}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.asset && (
        <DialogTitle id='form-dialog-title'>Update Asset</DialogTitle>
      )}
      {!props.asset && (
        <DialogTitle id='form-dialog-title'>Create a new Asset</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.asset && (
          <DialogContentText>Update the asset details:</DialogContentText>
        )}
        {!props.asset && (
          <DialogContentText>Input the asset details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <TextField
                id='network'
                label='Network'
                value={network}
                error={errorNetwork.length > 0}
                helperText={errorNetwork}
                onChange={e => setNetwork(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                id='category'
                label='Category'
                value={category}
                error={errorCategory.length > 0}
                helperText={errorCategory}
                onChange={e => setCategory(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                id='protocol'
                label='protocol'
                value={protocol}
                error={errorProtocol.length > 0}
                helperText={errorProtocol}
                onChange={e => setProtocol(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                id='label'
                label='Label'
                value={label}
                error={errorLabel.length > 0}
                helperText={errorLabel}
                onChange={e => setLabel(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='symbol'
                label='Symbol'
                value={symbol}
                error={errorSymbol.length > 0}
                helperText={errorSymbol}
                onChange={e => setSymbol(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                id='decimals'
                label='Decimals'
                value={decimals}
                error={errorDecimals.length > 0}
                helperText={errorDecimals}
                onChange={e => setDecimals(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='balance'
                label='Balance'
                value={balance}
                error={errorBalance.length > 0}
                helperText={errorBalance}
                onChange={e => setBalance(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                id='price'
                label='Price'
                value={price}
                error={errorPrice.length > 0}
                helperText={errorPrice}
                onChange={e => setPrice(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='priceId'
                label='Price Source'
                value={priceId}
                error={errorPriceId.length > 0}
                helperText={errorPriceId}
                onChange={e => setPriceId(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
              />
            </Grid>
          </Grid>
          <TextField
            id='imageUrl'
            label='image URL'
            value={imageUrl}
            error={errorImageUrl.length > 0}
            helperText={errorImageUrl}
            onChange={e => setImageUrl(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
          />
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e, val) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert as any}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation disabled={isLoading}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditWalletModal;