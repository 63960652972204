import useSWR from 'swr';
import { getUser } from "../../services/prime-users";
import { useAuthStore } from "../context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiUserProfile = ([_, userId]: [string, string]) => getUser(userId);

export const useApiUserProfile = (userId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && userId ? ['apiUserProfile', userId] : null, apiUserProfile, options);
  return {
    data: userId && data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};
