import useSWR from 'swr';

import { useAuthStore } from "../context/useAuthStore";
import { getBalancePortfolio } from "../../services/cfx-balance";

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  // refreshInterval: 15000
};

const apiBalancePortfolio = (_: string) => getBalancePortfolio();

export const useApiBalancePortfolio = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? 'apiBalancePortfolio' : null, apiBalancePortfolio, options);
  return {
    data: data?.data || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
