import { useState, useEffect } from 'react';

import { getTransactionUsers } from "../../../../services/connect-admin";
import { getTransactions } from "../../../../services/connect-user";

import { TransactionsTable } from './TransactionsTable';

export const ConnectTransactions = () => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadUserTransactions = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const users = await getTransactionUsers();
      const results = await Promise.all(users?.map((user: any) => getTransactions(user.starkkey)));
      console.log('results', results);
      const transactions = results?.map((result: any) => result.l2).flat().sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      console.log('transactions', transactions);
      const uniqueTransactions: any[] = [];
      for (const transaction of transactions) {
        if (!uniqueTransactions.find((x: any) => x.tx_id === transaction.tx_id)) {
          uniqueTransactions.push(transaction);
        } 
        if (uniqueTransactions.length >= 100) {
          break;
        }
      }
      console.log('uniqueTransactions', uniqueTransactions);
      setData(uniqueTransactions);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadUserTransactions();
  }, []);

  return (
    <TransactionsTable data={data} isLoading={isLoading} mutate={loadUserTransactions} />
  );
}