import { useMemo}from 'react';

import { shrinkNumber} from '../../../../helpers/shrink';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

const DEFAULT_MEDIA_URL = 'https://assets.prod.cloud.canvas.co/nft/nft-icon.svg';

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  name: {
    fontWeight: 700,
  },
  description: {
    padding: theme.spacing(1, 2, 2, 2),
    maxWidth: '600px',
  },
  collection: {
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  balance: {
    color: theme.palette.primary.main,
  },
  floorPrice: {
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  modalGrid: {
    padding: theme.spacing(4, 2, 1, 2)
  },
  image: {
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
    background: '#d2d2d2',
    border: 'solid 1px #eee'
  }  
}));


export const NFTModal = (props: any) => {

  const itemTitle = useMemo(() => `${props.data.collection || ''} ${props.data.collection && props.data.name ? '-' : ''} ${props.data.name || ''}`, [props.data]);

  const getMediaUrl = (url: string) => url ? url.replace('ipfs://', 'https://ipfs.io/ipfs/') : '';

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <img src={getMediaUrl(props.data.mediaUrl)} alt={props.data.name} className={classes.image} onError={(e) => e.currentTarget.src = DEFAULT_MEDIA_URL} />
        <Grid container justifyContent="space-between" spacing={3} className={classes.modalGrid}>
          <Grid item>
            <Typography variant="h5" className={classes.name}>{props.data.name}</Typography>
            <Typography variant="h6" className={classes.collection}>{props.data.collection}</Typography>
          </Grid>
          {props.data.balanceUSD !== 0 && (
            <Grid item style={{ textAlign: 'right' }}>
              <Typography variant="h5" className={classes.balance}>{shrinkNumber(Number(props.data.balanceUSD))}</Typography>
              <Typography variant="h6" className={classes.floorPrice}>{props.data.floorPrice.toFixed(4)} ETH</Typography>
            </Grid>
          )}
        </Grid>
        <Typography className={classes.description}>{props.data.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeModal()} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
