import { useEffect } from 'react';

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useNavigate } from "react-router-dom";

import { AppLayout } from '../../shared/AppLayout/AppLayout';

import { TransactionTabs } from './/TransactionTabs';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cards: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  heading: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const Transactions = () => {
  const navigate = useNavigate();
  const { isAuthorized } = useAuthStore();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Transaction History</h1>
        <h4 className={classes.subtitle}>View <span>Past &amp; Present</span> Trades</h4>
        <TransactionTabs />
      </div>
    </AppLayout>
  );
};