import { useState } from 'react';
import clsx from 'clsx';

import EditWalletModal from './EditWalletModal';
import DeleteWalletModal from './DeleteWalletModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  }
}));

const WalletTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState('');

  const selectWallet = (id?: string) => {
    props.onSelect(id || '');
    setSelectedWallet(id || '');
  }

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectWallet();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectWallet(data?.find((x: any) => x.walletId === id));
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectWallet(data?.find((x: any) => x.walletId === id));
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectWallet(id);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectWallet();
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectWallet();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectWallet();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>ID</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Name</b></TableCell>
            <TableCell className={classes.cell} align="right"><IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row: any) => (
            <TableRow key={row.walletId} component="tr" onClick={() => props.onSelect(row.walletId)} className={clsx({ [classes.selected]: (props.selectedWalletId === row.walletId) })}>
              <TableCell className={classes.cell} align="left" component="th" scope="row"><h5>{row.walletId}</h5></TableCell>
              <TableCell className={classes.cell} align="left">{row.name}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                <IconButton onClick={() => handleOpenEditModal(row.walletId)}><EditIcon /></IconButton>
                <IconButton onClick={() => handleOpenDeleteModal(row.walletId)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditWalletModal wallet={selectedWallet} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteWalletModal wallet={selectedWallet} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
export default WalletTable;