import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useApiFireblocksSecrets } from "../../hooks/prime/useApiFireblocksSecrets";
import { useApiFireblocksExchanges } from "../../hooks/prime/useApiFireblocksExchanges";
import { useApiFireblocksVaults } from "../../hooks/prime/useApiFireblocksVaults";

import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import SecretTable from "./components/secret/SecretTable";
import ExchangeTable from "./components/exchange/ExchangeTable";
import VaultTable from "./components/vault/VaultTable";

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: '1.2rem',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const FireblocksConfig = () => {
  const { isAuthorized } = useAuthStore();
  const [selectedSecretId, setSelectedSecretId] = useState('');
  const { data: dataSecrets, isLoading: isLoadingSecrets, mutate: mutateSecrets } = useApiFireblocksSecrets();
  const { data: dataExchanges, isLoading: isLoadingExchanges, mutate: mutateExchanges } = useApiFireblocksExchanges();
  const { data: dataVaults, isLoading: isLoadingVaults, mutate: mutateVaults } = useApiFireblocksVaults();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectSecret = (secretId: string) => {
    setSelectedSecretId(secretId);
  };

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Fireblocks Configuration</h1>
      </div>
      {isLoadingSecrets && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
      {!isLoadingSecrets && dataSecrets && (
        <Paper className={classes.paper}>
          <SecretTable data={dataSecrets} isLoading={isLoadingSecrets} mutate={mutateSecrets} selectedSecretId={selectedSecretId} onSelect={selectSecret} />
        </Paper>
      )}
      {selectedSecretId && (
        <>
          {isLoadingExchanges && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
          {!isLoadingExchanges && dataExchanges && (
            <>
              <Paper className={classes.paper}>
                <h2 className={classes.subtitle}>Exchanges</h2>
                <ExchangeTable data={dataExchanges} isLoading={isLoadingExchanges} mutate={mutateExchanges} secretId={selectedSecretId} />
              </Paper>
            </>
          )}
          {isLoadingSecrets && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
          {!isLoadingSecrets && dataSecrets && (
          <Paper className={classes.paper}>
            <h2 className={classes.subtitle}>Vaults</h2>
            <VaultTable data={dataVaults} isLoading={isLoadingVaults} mutate={mutateVaults} secretId={selectedSecretId} />
          </Paper>
          )}
        </>
      )}
    </AppLayout>
  );
};