import { IconBadge } from '../../../../shared/UI/IconBadge';

import { makeStyles } from 'tss-react/mui';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const useStyles = makeStyles()((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: theme.spacing(1),
      height: theme.spacing(1),
      position: 'absolute'
    },
    "&::-webkit-scrollbar-track": {
      background: '#f0f1f2',
      borderRadius: '100px'
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
  tableContainer: {
    minWidth: 650,
  },
  date: {
    whiteSpace: 'nowrap'
  }
}));

export const BalanceTable = (props: any) => {
  const { data } = props;
  const {classes} = useStyles();
  return (
    <TableContainer className={classes.root}>
      <Table className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Asset</TableCell>
            <TableCell align='center'>Symbol</TableCell>
            <TableCell align='right'>Balance</TableCell>
            <TableCell align='right'>Price</TableCell>
            <TableCell align='center'>APR</TableCell>
            <TableCell align='right'>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item: any, index: number) => (
            <TableRow key={index}>
            <TableCell align='center'><IconBadge mainIcon={item.imgUrl} /></TableCell>
            <TableCell align='center'>{item.label}</TableCell>
            <TableCell align='center'>{item.symbol}</TableCell>
            <TableCell align='right'>{item.balance.toFixed(4)}</TableCell>
            <TableCell align='right'>{item.price.toFixed(4)}</TableCell>
            <TableCell align='right'>{item.apr ? `${item.apr.toFixed(2)}%` : ''}</TableCell>
            <TableCell align='right'>{item.balanceUSD.toFixed(4)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
