import useSWR from 'swr';
import { getAllTranactions } from "../../services/prime-trade";
import { useAuthStore } from "../context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiAllTradeTransactions = ([_]: [string]) => getAllTranactions();

export const useApiAllTradeTransactions = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiAllTradeTransactions'] : null, apiAllTradeTransactions, options);
  return {
    data: data && data.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};
