import { useState } from 'react';
import { deleteGroupUser } from "../../../services/prime-users";

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  content: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export const DeleteGroupUserModal = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
  };

  const handleSubmit = () => {
    console.log('handleSubmit');
    setLoading(true);
    setMessage({ type: 'warning', description: `Removing User: <strong>${props.selectedGroupUser?.name}</strong> from Group: <strong>${props.selectedGroupUser?.groupId}</strong> ... ` });
    deleteGroupUser(props.selectedGroupUser?.groupId, props.selectedGroupUser?.userId).then((data: any) => {
      console.log('Delete Group', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${props.selectedGroupUser?.name}</strong> has been removed from Group: <strong>${props.selectedGroupUser?.groupId}</strong> <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(props.selectedGroupUser?.groupId);
        clearErrors();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete User Group</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Are you sure you want to <strong>remove</strong> the user from this Group:</DialogContentText>
        <div className={classes.content}>
          <h3>{props.selectedGroupUser?.name}</h3>
          <h5>{props.selectedGroupUser?.groupId}</h5>
        </div>
        {message && message.description &&
          <Alert
            variant='outlined'
            severity={message.type as any}
            className={classes.alert as any}
            action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
          >
            <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary">Cancel</Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained" disabled={isLoading} disableElevation>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
