import { useState, useEffect } from 'react';

import { createUser } from '../../../services/prime-users';
import { useApiGroupList } from '../../../hooks/prime/useApiGroupList';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const CreateUserModal = (props: any) => {
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiGroupList();

  const [groupId, setGroupId] = useState('');
  const [roles, setRoles] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [password, setPassword] = useState('');

  const [errorGroup, setErrorGroup] = useState('');
  const [errorRoles, setErrorRoles] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorUsername, setErrorUsername] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPictureUrl, setErrorPictureUrl] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    setGroupId(props?.selectedGroup?.groupId || '');
  }, [props.selectedGroup])

  const clearForm = () => {
    setGroupId('');
    setRoles('');
    setName('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPassword('');
    setShowPassword(false);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorGroup('');
    setErrorRoles('');
    setErrorName('');
    setErrorUsername('');
    setErrorEmail('');
    setErrorPhone('');
    setErrorPassword('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (groupId.length === 0) {
      setErrorGroup('Select the group');
      return false;
    }
    if (name.length === 0) {
      setErrorName('Enter the name');
      return false;
    }
    if (username.length === 0) {
      setErrorUsername('Enter the username');
      return false;
    }
    if (email.length === 0) {
      setErrorEmail('Enter the email');
      return false;
    }
    if (phone.length === 0) {
      setErrorPhone('Enter the phone');
      return false;
    }
    if (pictureUrl.length === 0) {
      setErrorPictureUrl('Enter the pictureUrl');
      return false;
    }
    if (password.length === 0) {
      setErrorPassword('Enter the password');
      return false;
    }
    if (password.length < 8) {
      setErrorPassword('Enter a password with a minimum of 8 characters');
      return false;
    }
    if (!password.match(/[a-z]+/)) {
      setErrorPassword('Enter a password with a lower case character');
      return false;
    }
    if (!password.match(/[A-Z]+/)) {
      setErrorPassword('Enter a password with an upper case character');
      return false;
    }
    if (!password.match(/[0-9]+/)) {
      setErrorPassword('Enter a password with a numeric character');
      return false;
    }
    if (!password.match(/^[a-zA-Z0-9]+/)) {
      setErrorPassword('Enter a password with a special character');
      return false;
    }    
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleAddNew();
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating User: <strong>${name}</strong> ... ` });
    createUser(name.trim(), username.trim(), email.trim(), phone.trim(), pictureUrl.trim(), password).then((data) => {
      console.log('createUser', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const handleMutate = () => {
    mutateGroupList();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <DialogTitle id='form-dialog-title'>Create User</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          {isLoadingGroupList && (
            <IconButton><CircularProgress color="primary" size={24} /></IconButton>
          )}
          {!isLoadingGroupList && (
            <IconButton color="primary" disabled={isLoadingGroupList} onClick={() => handleMutate()}><RefreshIcon /></IconButton>
          )}
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='groupId-label'>group</InputLabel>
            <Select
              labelId='groupId-label'
              id='groupId'
              label="group"
              value={groupId}
              onChange={(e: any) => setGroupId(e.target.value)}
              autoFocus
            >
              {dataGroupList?.map((group: any) => (
                <MenuItem key={group.groupId} value={group.groupId}>{group.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorGroup}</FormHelperText>
          </FormControl>
          <TextField
            id='roles'
            label='group roles'
            value={roles}
            error={errorRoles.length > 0}
            helperText={errorRoles}
            onChange={(e: any) => setRoles(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={(e: any) => setName(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id='username'
                label='username'
                value={username}
                error={errorUsername.length > 0}
                helperText={errorUsername}
                onChange={(e: any) => setUsername(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='phone'
                label='phone'
                value={phone}
                error={errorPhone.length > 0}
                helperText={errorPhone}
                onChange={(e: any) => setPhone(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>

          <TextField
            id='email'
            label='email'
            value={email}
            error={errorEmail.length > 0}
            helperText={errorEmail}
            onChange={(e: any) => setEmail(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <TextField
            id='pictureUrl'
            label='picture'
            value={pictureUrl}
            error={errorPictureUrl.length > 0}
            helperText={errorPictureUrl}
            onChange={(e: any) => setPictureUrl(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            InputProps={{
              endAdornment: (
                <img src={pictureUrl} alt="" />
              )
            }}
          />

          <TextField
            id='password'
            label='password'
            value={password}
            error={errorPassword.length > 0}
            helperText={errorPassword}
            onChange={(e: any) => setPassword(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
