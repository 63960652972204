import { useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { useAuthStore } from "../../../../hooks/context/useAuthStore";
import { shrinkULID } from "../../../../helpers/shrink";

import { TransactionModal } from './TransactionModal';
import { TradeInfo } from '../../../../shared/Trade/TradeInfo';
import { TradeState } from '../../../../shared/Trade/TradeState';
import { TradeMessagesSml } from '../../../../shared/Trade/TradeMessagesSml';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  symbol: {
    fontWeight: 500
  },
  status: {
    display: 'inline-block',
    minWidth: '70px',
    lineHeight: '1.5rem',
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    borderRadius: 12
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SendAsset = (props: any) => {
  const { classes } = useStyles();
  if (props.currentUser.userId === props.trade?.userId) {
    return (<>{Number(props.trade?.amount).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolSend}</span></>);
  } else {
    return (<>{(props.trade?.amount * props.trade?.rate).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolRecv}</span></>);
  }
}

const ReceiveAsset = (props: any) => {
  const { classes } = useStyles();
  if (props.currentUser.userId === props.trade?.userId) {
    return (<>{(props.trade?.amount * props.trade?.rate).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolRecv}</span></>);
  } else {
    return (<>{Number(props.trade?.amount).toFixed(2)} <span className={classes.symbol}>{props.trade?.symbolSend}</span></>);
  }
}

const Fee = (props: any) => {
  const { classes } = useStyles();
  if (props.currentUser.userId === props.trade?.l1.userId) {
    return (<>{props.trade?.l1.feeAmount?.toFixed(4)} <span className={classes.symbol}>{props.trade?.l1.symbol}</span></>);
  } else {
    return (<>{props.trade?.l2.feeAmount?.toFixed(4)} <span className={classes.symbol}>{props.trade?.l2.symbol}</span></>);
  }
}

const Counterparty = (props: any) => {
  if (props.currentUser.userId === props.trade?.l1.userId) {
    return props.trade?.l2.username;
  } else {
    return props.trade?.l1.username;
  }
}

export const TransactionTable = (props: any) => {
  const { currentUser } = useAuthStore();
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState<any>();

  const selectTrade = (id?: string) => {
    console.log('selectTrade', id);
    if (id) {
      const trade = props.data?.find((x: any) => x.tradeId === id);
      console.log(trade);
      setSelectedTrade(trade);
    } else {
      setSelectedTrade(undefined);
    }
  }

  const handleOpenTransactionModal = (id: string) => {
    console.log('handleOpenTransactionModal', id);
    selectTrade(id);
    setOpenTransactionModal(true);
  };

  const closeTransactionModal = () => {
    console.log('closeInfo');
    setOpenTransactionModal(false);
    selectTrade();
    // props.mutate();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Created</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>ID</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Sent</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>You Received</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Rate</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Fee</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Counterparty</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Info</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              {props.isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!props.isLoading && (
                <IconButton color="primary" disabled={props.isLoading} onClick={() => props.mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.map((row: any) => (
            <TableRow component="tr" key={row.tradeId} onClick={() => selectTrade(row.tradeId)} className={clsx(classes.row, { [classes.selected]: (props.selectedTradeId === row.tradeId) })}>
              <TableCell className={classes.cell} align="center">{format(new Date(row.createdAt), 'dd-MMM-yyyy HH:mm')}</TableCell>
              <TableCell className={classes.cell} align="center"><span className={classes.symbol}>{shrinkULID(row.tradeId)}</span></TableCell>
              <TableCell className={classes.cell} align="center"><SendAsset trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center"><ReceiveAsset trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center">{Number(row.rate).toFixed(4)} {row.symbolSend}</TableCell>
              <TableCell className={classes.cell} align="center"><Fee trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center"><Counterparty trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center"><TradeState trade={row} currentUser={currentUser} /></TableCell>
              <TableCell className={classes.cell} align="center">
                <Tooltip title={<TradeInfo trade={row} />}><InfoIcon className={classes.icon} /></Tooltip>
                {row.logs?.length > 0 && (
                  <Tooltip title={<TradeMessagesSml trade={row} />}><MessageIcon className={classes.icon} /></Tooltip>
                )}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                <Button variant="outlined" color="primary" disableElevation onClick={() => handleOpenTransactionModal(row.tradeId)} startIcon={<LaunchIcon />}>view</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TransactionModal trade={selectedTrade} open={openTransactionModal} closeModal={closeTransactionModal} />
    </>
  );
};
