import { useState, useEffect } from 'react';
import { createExchange, updateExchange } from '../../../../services/prime-admin';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const isExchangeId = (s: string) => {
  const fbExchangeAddressPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return fbExchangeAddressPattern.test(s);
};

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditExchangeModal = (props: any) => {
  const [exchange, setExchange] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(false);

  const [errorExchange, setErrorExchange] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.exchange) {
      setExchange(props.exchange.exchange || '');
      setDescription(props.exchange.description || '');
      setStatus(props.exchange.status === true);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.exchange])

  const clearForm = () => {
    setExchange('');
    setDescription('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorExchange('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (exchange?.length === 0 || !isExchangeId(exchange)) {
      setErrorExchange('Enter the exchange UUID');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.exchange) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating exchange: <strong>${exchange}</strong> ... ` });
    createExchange(props.secretId, exchange.trim(), description.trim(), (status === true)).then((data) => {
      console.log('createExchange', data);
      if (data) {
        setMessage({ type: 'success', description: `Exchange: <strong>${exchange}</strong>, created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating exchange details: <strong>${exchange}</strong> ... ` });
    updateExchange(props.exchange.secret, props.exchange.exchangeId, exchange.trim(), description.trim(), (status === true)).then((data) => {
      console.log('UpdateExchange', data);
      if (data) {
        setMessage({ type: 'success', description: `Exchange: <strong>${exchange}</strong>, updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.exchange && (
        <DialogTitle id='form-dialog-title'>Update Fireblocks Exchange</DialogTitle>
      )}
      {!props.exchange && (
        <DialogTitle id='form-dialog-title'>Create a new Fireblocks Exchange</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.exchange && (
          <DialogContentText>Update the exchange details:</DialogContentText>
        )}
        {!props.exchange && (
          <DialogContentText>Input the exchange details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                id='secretId'
                label='Fireblocks Secret'
                value={props.secretId}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id='exchange'
                label='Exchange Uid'
                value={exchange}
                error={errorExchange.length > 0}
                helperText={errorExchange}
                onChange={e => setExchange(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
          <TextField
            id='description'
            label='Description'
            value={description}
            onChange={e => setDescription(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
            autoFocus
          />
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e, val) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert as any}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation disabled={isLoading}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditExchangeModal;