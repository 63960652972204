import useSWR from 'swr';
import { getTransactions } from "../../services/prime-wallets";
import { useAuthStore } from "../context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiTransactions = ([_, userId]: [string, string]) => getTransactions(userId);

export const useApiTransactions = (userId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && userId ? ['apiTransactions', userId] : null, apiTransactions, options);
  return {
    data: userId && data && data.status ? data.transactions : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};
