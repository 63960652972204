import React, { useMemo } from 'react';
import { format } from 'date-fns';

import { shrinkAddress } from "../../../../helpers/shrink";
import { IconBadgeSml } from "../../../../shared/UI/IconBadgeSml";

import CoinIcon from "../../../../assets/images/coin-icon.png";
import ConnectIcon from "../../../../assets/networks/connect.png";

import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  overflow: {
    width: '100%',
    overflow: 'scroll-x',
  },
  table: {
    width: '100%',
  },
  cell: {
    whiteSpace: 'nowrap',
    lineHeight: '1rem',
    padding: theme.spacing(1, 2),
  },
  label: {
    fontWeight: 500
  },
  type: {
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  symbol: {
    fontWeight: 500
  },
  address: {
    fontSize: '0.75rem',
    color: '#808191',
  },
  amount: {
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 700
    }
  },
}));

const numToFixed = (s: string, toFixed: number) => {
  const num = Number(s);
  if (!isNaN(num)) {
    return num.toFixed(toFixed);
  }
  return '';
};

export const TransactionsTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const l2Data = useMemo(() => data ? [...data].reverse() : [], [data]);
  const { classes } = useStyles();
  return (
    <div className={classes.overflow}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>ID</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Transaction</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>From</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>To</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Status</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Updated</b></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {l2Data?.map((row: any) => (
            <React.Fragment key={row.tx_id}>
              {row.transaction_type === 'TRANSFER' && (
                <TableRow>
                  <TableCell className={classes.cell}>
                    <div className={classes.label}>{row.tx_id}</div>
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.label}>{row.transaction_type}</div>
                  </TableCell>
                  <TableCell className={classes.cell}>

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'right' }}><IconBadgeSml mainIcon={row.fromIcon ? row.fromIcon : CoinIcon} /></Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.amount}><span>{numToFixed(row.user_readable_amount, 4)}</span> {row.token_name}</div>
                        <div className={classes.address}>token: {shrinkAddress(row.token_id)}</div>
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="center">

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'right' }}><IconBadgeSml mainIcon={row.toIcon ? row.toIcon : ConnectIcon} /></Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.amount}><span>{numToFixed(row.user_readable_amount, 4)}</span> {row.token_name}</div>
                        <div className={classes.address}>starkkey: {shrinkAddress(row.receiver_stark_key)}</div>
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.label}>COMPLETED</div>
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.label}>{format(new Date(row.createdAt), 'dd-MMM-yyyy, H:mm a')}</div>
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                  </TableCell>
                </TableRow>
              )}
              {row.transaction_type !== 'TRANSFER' && (
                <TableRow>
                  <TableCell className={classes.cell}>
                    <div className={classes.label}>{row.tx_id}</div>
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.type}>{row.transaction_type}</div>
                  </TableCell>
                  <TableCell className={classes.cell}>

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'right' }}><IconBadgeSml mainIcon={row.fromIcon ? row.fromIcon : CoinIcon} /></Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.amount}><span>{numToFixed(row.user_readable_sell_amount, 4)}</span> {row.sell_token_name}</div>
                        <div className={classes.address}>{shrinkAddress(row.sell_token_id)}</div>
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="center">

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'right' }}><IconBadgeSml mainIcon={row.toIcon ? row.toIcon : CoinIcon} /></Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.amount}><span>{numToFixed(row.user_readable_buy_amount, 4)}</span> {row.buy_token_name}</div>
                        <div className={classes.address}>{shrinkAddress(row.buy_token_id)}</div>
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.label}>COMPLETED</div>
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.label}>{format(new Date(row.createdAt), 'dd-MMM-yyyy, H:mm a')}</div>
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
