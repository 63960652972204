import React from 'react';
import { format } from 'date-fns';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logDate: {
    fontSize: '0.75rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  logMsg: {
    fontSize: '0.75rem',
    fontWeight: 300,
    textAlign: 'left'
  },
}));

export const TradeMessagesSml = (props: any) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={1} className={classes.grid}>
      {props.trade?.logs?.map((log: any) => (
        <React.Fragment key={log.loggedAt}><Grid item xs={3} className={classes.logDate}>{format(new Date(log.loggedAt), 'HH:mm:ss')}</Grid><Grid item xs={9} className={classes.logMsg}>{log.message}</Grid></React.Fragment>
      ))}
    </Grid>
  );
}