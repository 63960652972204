import useSWR from 'swr';
import { useAuthStore } from "../context/useAuthStore";
import { getTokenConfigList } from "../../services/prime-admin";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiTokenConfigList = ([_]: any[]) => getTokenConfigList();

export const useApiTokenConfigList = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiTokenConfigList'] : null, apiTokenConfigList, options);
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
