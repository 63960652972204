import { api } from "./api-prime";

const request = api('admin');

//
// Address Config
//
export const getAddressConfigList = async () => {
  return await request(`/address/config`);
};

export const deleteAddress = async (address: string) => {
  return await request(`/address/config/${address}`, 'delete');
};

export const createAddress = async (address: string, description: string, sources: any, status: boolean) => {
  return await request(`/address/config`, 'post', undefined, { address, description, sources, status });
};

export const updateAddress = async (address: string, description: string, sources: any, status: boolean) => {
  return await request(`/address/config/${address}`, 'put', undefined, { description, sources, status });
};

//
// Token Config
//
export const getTokenConfigList = async () => {
  return await request(`/token/config`);
};

export const deleteToken = async (token: string, sk: string) => {
  return await request(`/token/config/${token}/${sk}`, 'delete');
};

export const createToken = async (token: string, network: string, category: string, protocol: string, label: string, symbol: string, decimals: string, price: string, priceId: string, tokenImageUrl: string, protocolImageUrl: string, status: boolean) => {
  return await request(`/token/config`, 'post', undefined, { token, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status });
};

export const updateToken = async (token: string, sk: string, network: string, category: string, protocol: string, label: string, symbol: string, decimals: string, price: string, priceId: string, tokenImageUrl: string, protocolImageUrl: string, status: boolean) => {
  return await request(`/token/config/${token}/${sk}`, 'put', undefined, { token, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status });
};


//
// Fireblocks Config
//
export const getFireblocksSecrets = () => request('/fireblocks/secret');
export const getFireblocksExchanges = () => request(`/fireblocks/exchange`);
export const getFireblocksVaults = () => request('/fireblocks/vault');

export const createSecret = async (groupId: string, name: string, description: string, secret: string) => {
  return await request(`/fireblocks/secret`, 'post', undefined, { groupId, name, description, secret });
};

export const updateSecret = async (secretId: string, groupId: string, description: string) => {
  return await request(`/fireblocks/secret/${secretId}`, 'put', undefined, { groupId, description });
};

export const patchSecret = async (secretId: string, secret: string) => {
  return await request(`/fireblocks/secret/${secretId}`, 'patch', undefined, { secret });
};

export const deleteSecret = async (secretId: string) => {
  return await request(`/fireblocks/secret/${secretId}`, 'delete');
};

export const createExchange = async (secretId: string, exchange: string, description: string, status: boolean) => {
  const data = {
    secret: secretId,
    exchange: exchange,
    description: description,
    status: status
  };
  return await request(`/fireblocks/exchange/`, 'post', undefined, data);
};

export const updateExchange = async (secretId: string, exchangeId: string, exchange: string, description: string, status: boolean) => {
  const data = {
    secret: secretId,
    exchange: exchange,
    description: description,
    status: status
  };
  return await request(`/fireblocks/exchange/${exchangeId}`, 'put', undefined, data);
};

export const deleteExchange = async (exchangeId: string) => {
  return await request(`/fireblocks/exchange/${exchangeId}`, 'delete');
};

export const createVault = async (secretId: string, vault: string, whitelistId: string, description: string, status: boolean) => {
  const data = {
    secret: secretId,
    vault: vault,
    whitelistId: whitelistId,
    description: description,
    status: status
  };
  return await request(`/fireblocks/vault/`, 'post', undefined, data);
};

export const updateVault = async (secretId: string, vaultId: string, vault: string, whitelistId: string, description: string, status: boolean) => {
  const data = {
    secret: secretId,
    vault: vault,
    whitelistId: whitelistId,
    description: description,
    status: status
  };
  return await request(`/fireblocks/vault/${vaultId}`, 'put', undefined, data);
};

export const deleteVault = async (vaultId: number) => {
  return await request(`/fireblocks/vault/${vaultId}`, 'delete');
};


//
// OFfchain Assets
//
export const getOffchainWallets = () => request(`/offchain/wallet`);
export const getOffchainAssets = (walletId: string) => request(`/offchain/asset/${walletId}`);


export const createWallet = async (name: string) => {
  return await request(`/offchain/wallet`, 'post', undefined, { name });
};

export const updateWallet = async (walletId: string, name: string) => {
  return await request(`/offchain/wallet/${walletId}`, 'put', undefined, { name });
};

export const deleteWallet = async (walletId: string) => {
  return await request(`/offchain/wallet/${walletId}`, 'delete');
};

export const createAsset = async (walletId: string, network: string, category: string, protocol: string, label: string, symbol: string, decimals: string, balance: string, price: string, priceId: string, imageUrl: string, status: boolean) => {
  const data = {
    walletId, network, category, protocol, label, symbol, decimals, balance, price, priceId, imageUrl, status
  };
  return await request(`/offchain/asset/${walletId}`, 'post', undefined, { data });
};

export const updateAsset = async (walletId: string, assetId: string, network: string, category: string, protocol: string, label: string, symbol: string, decimals: string, balance: string, price: string, priceId: string, imageUrl: string, status: boolean) => {
  const data = {
    walletId, assetId, network, category, protocol, label, symbol, decimals, balance, price, priceId, imageUrl, status
  };
  return await request(`/offchain/asset/${walletId}/${assetId}`, 'put', undefined, { data });
};

export const deleteAsset = async (walletId: string, assetId: string) => {
  return await request(`/offchain/asset/${walletId}/${assetId}`, 'delete');
};


