import { api } from "./api-prime";

const request = api('admin');

export const getUsers = async () => {
  return await request(`auth/user`);
};

export const getUser = async (userId: string) => {
  return await request(`auth/user/${userId}`);
};

export const createUser = async (name: string, username: string, email: string, phone: string, pictureUrl: string, password: string) => {
  return await request(`auth/user`, 'post', undefined, { name, username, email, phone, pictureUrl, password });
};

export const updateUser = async (userId: string, name: string, username: string, email: string, phone: string, pictureUrl: string) => {
  return await request(`auth/user/${userId}`, 'put', undefined, { name, username, email, phone, pictureUrl });
};

export const deleteUser = async (userId: string) => {
  return await request(`auth/user/${userId}`, 'delete');
};

export const blockUser = async (userId: string) => {
  return await request(`auth/user/${userId}/block`, 'put');
};

export const unblockUser = async (userId: string) => {
  return await request(`auth/user/${userId}/block`, 'delete');
};

export const changePassword = async (userId: string, password: string) => {
  return await request(`auth/user/${userId}`, 'patch', undefined, { password });
};

export const getGroups = async () => {
  return await request(`auth/group`);
};

export const createGroup = async (name: string, description: string, status: boolean) => {
  return await request(`auth/group`, 'post', undefined, { name, description, status });
};

export const updateGroup = async (groupId: string, name: string, description: string, status: boolean) => {
  return await request(`auth/group/${groupId}`, 'put', undefined, { name, description, status });
};

export const deleteGroup = async (groupId: string) => {
  return await request(`auth/group/${groupId}`, 'delete');
};

export const getUserGroups = async (userId: string) => {
  return await request(`auth/user/${userId}/group`, 'get');
};

export const getGroupUser = async (groupId: string, userId: string) => {
  return await request(`auth/group/${groupId}/user/${userId}`, 'get');
};

export const getGroupUsers = async (groupId: string) => {
  return await request(`auth/group/${groupId}/user`, 'get');
};

export const getGroupUserList = async () => {
  return await request(`auth/group/user`, 'get');
};

export const updateGroupUser = async (groupId: string, userId: string, roles: string, status: boolean) => {
  return await request(`auth/group/${groupId}/user/${userId}`, 'put', undefined, { userId, groupId, roles, status });
};

export const deleteGroupUser = async (groupId: string, userId: string) => {
  return await request(`auth/group/${groupId}/user/${userId}`, 'delete');
};