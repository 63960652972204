import { useState, useEffect } from 'react';
import { createWallet, updateWallet } from "../../../../services/prime-admin";

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditWalletModal = (props: any) => {
  const [name, setName] = useState('');

  const [errorName, setErrorName] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.wallet) {
      setName(props.wallet.name);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.wallet])

  const clearForm = () => {
    setName('');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorName('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (name?.length === 0) {
      setErrorName('Enter the wallet name.');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.wallet) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating wallet: <strong>${name}</strong> ... ` });
    createWallet(name.trim()).then((data) => {
      console.log('CreateWallet', data);
      if (data) {
        setMessage({ type: 'success', description: `Wallet: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating wallet details: <strong>${name}</strong> ... ` });
    updateWallet(props.wallet?.walletId, name.trim()).then((data) => {
      console.log('UpdateWallet', data);
      if (data) {
        setMessage({ type: 'success', description: `Wallet: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.wallet && (
        <DialogTitle id='form-dialog-title'>Update Wallet</DialogTitle>
      )}
      {!props.wallet && (
        <DialogTitle id='form-dialog-title'>Create a new Wallet</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.wallet && (
          <DialogContentText>Update the wallet details:</DialogContentText>
        )}
        {!props.wallet && (
          <DialogContentText>Input the wallet details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={e => setName(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditWalletModal;