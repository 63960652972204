import { useState, useEffect } from 'react';

import { updateUser } from '../../../services/prime-users';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  thumbnail: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#c4c4c4',
    marginTop: theme.spacing(2),
    height: '56px',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: '#c4c4c4'
  }
}));

export const EditUserModal = (props: any) => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');

  const [errorName, setErrorName] = useState('');
  const [errorUsername, setErrorUsername] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorPictureUrl, setErrorPictureUrl] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    clearForm();
    clearErrors();
    setName(props.selectedUser?.name);
    setUsername(props.selectedUser?.username);
    setEmail(props.selectedUser?.email);
    setPhone(props.selectedUser?.phone);
    setPictureUrl(props.selectedUser?.picture);
  }, [props.selectedUser, props.open])

  const clearForm = () => {
    setName('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPictureUrl('');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorName('');
    setErrorUsername('');
    setErrorEmail('');
    setErrorPhone('');
    setErrorPictureUrl('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (name.length === 0) {
      setErrorName('Enter the name');
      return false;
    }
    if (username.length === 0) {
      setErrorUsername('Enter the username');
      return false;
    }
    if (email.length === 0) {
      setErrorEmail('Enter the email');
      return false;
    }
    if (phone.length === 0) {
      setErrorPhone('Enter the phone');
      return false;
    }
    if (pictureUrl.length === 0) {
      setErrorPictureUrl('Enter the pictureUrl');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleAddNew();
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating user: <strong>${name}</strong> ... ` });
    updateUser(props.selectedUser.userId, name.trim(), username.trim(), email.trim(), phone.trim(), pictureUrl.trim()).then((data) => {
      console.log('updateUser', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${name}</strong> details have been updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <DialogTitle id='form-dialog-title'>Update User Profile</DialogTitle>
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={(e: any) => setName(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id='username'
                label='username'
                value={username}
                error={errorUsername.length > 0}
                helperText={errorUsername}
                onChange={(e: any) => setUsername(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='phone'
                label='phone'
                value={phone}
                error={errorPhone.length > 0}
                helperText={errorPhone}
                onChange={(e: any) => setPhone(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>

          <TextField
            id='email'
            label='email'
            value={email}
            error={errorEmail.length > 0}
            helperText={errorEmail}
            onChange={(e: any) => setEmail(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                id='pictureUrl'
                label='picture'
                value={pictureUrl}
                error={errorPictureUrl.length > 0}
                helperText={errorPictureUrl}
                onChange={(e: any) => setPictureUrl(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <div className={classes.thumbnail} style={{ backgroundImage: `url(${pictureUrl})` }} />
            </Grid>
          </Grid>

          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
