import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useApiOffchainWallets } from "../../hooks/prime/useApiOffchainWallets";
import { useApiOffchainAssets } from "../../hooks/prime/useApiOffchainAssets";

import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { AppLayout } from '../../shared/AppLayout/AppLayout';

import WalletTable from "./components/wallet/WalletTable";
import AssetsTable from "./components/asset/AssetTable";

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: '1.2rem',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const OffchainConfig = () => {
  const { isAuthorized } = useAuthStore();
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const { data: dataAssets, isLoading: isLoadingAssets, mutate: mutateAssets } = useApiOffchainAssets(selectedWalletId);
  const { data: dataWallets, isLoading: isLoadingWallets, mutate: mutateWallets } = useApiOffchainWallets();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectWallet = (walletId: string) => {
    setSelectedWalletId(walletId);
  };

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Offchain Configuration</h1>
      </div>
      {isLoadingWallets && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
      {!isLoadingWallets && dataWallets && (
        <Paper className={classes.paper}>
          <WalletTable data={dataWallets} isLoading={isLoadingWallets} mutate={mutateWallets} selectedWalletId={selectedWalletId} onSelect={selectWallet} />
        </Paper>
      )}
      {selectedWalletId && (
        <>
          {isLoadingAssets && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
          {!isLoadingAssets && dataAssets && (
            <Paper className={classes.paper}>
              <h2 className={classes.subtitle}>Assets</h2>
              <AssetsTable data={dataAssets} isLoading={isLoadingAssets} mutate={mutateAssets} walletId={selectedWalletId} />
            </Paper>
          )}
        </>
      )}
    </AppLayout>
  );
};