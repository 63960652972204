import { useState, useEffect } from 'react';
import { createFeePolicy, updateFeePolicy } from '../../../services/connect-fees';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  text: {
    marginTop: theme.spacing(4),
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditFeeModal = (props: any) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [operationType, setOperationType] = useState('');
  const [assetId, setAssetId] = useState('');
  const [ratioDividend, setRatioDividend] = useState('');
  const [ratioDivisor, setRatioDivisor] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [ccu, setCcu] = useState('');

  const [errorName, setErrorName] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorOperationType, setErrorOperationType] = useState('');
  const [errorAssetId, setErrorAssetId] = useState('');
  const [errorRatioDividend, setErrorRatioDividend] = useState('');
  const [errorRatioDivisor, setErrorRatioDivisor] = useState('');
  const [errorMinAmount, setErrorMinAmount] = useState('');
  const [errorCcu, setErrorCcu] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.selectedFee) {
      setName(props.selectedFee.name);
      setDescription(props.selectedFee.description);
      setOperationType(props.selectedFee.type || props.selectedFee.operationType);
      setAssetId(props.selectedFee.defaultFee?.asset_id);
      setRatioDividend(props.selectedFee.defaultFee?.ratio?.dividend?.toString());
      setRatioDivisor(props.selectedFee.defaultFee?.ratio?.divisor?.toString());
      setMinAmount(props.selectedFee.defaultFee?.min_amount?.toString());
      setCcu(props.selectedFee.defaultFee?.ccu?.toString());
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.selectedFee])

  const clearForm = () => {
    setName('');
    setDescription('');
    setOperationType('');
    setAssetId('');
    setRatioDividend('');
    setRatioDivisor('');
    setMinAmount('');
    setCcu('');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorName('');
    setErrorDescription('');
    setErrorOperationType('');
    setErrorAssetId('');
    setErrorRatioDividend('');
    setErrorRatioDivisor('');
    setErrorMinAmount('');
    setErrorCcu('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (name?.length === 0) {
      setErrorName('Enter the policy name.');
      return false;
    }
    if (description?.length === 0) {
      setErrorDescription('Enter the policy description.');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.selectedFee) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating Fee Policy: <strong>${name}</strong> ... ` });
    createFeePolicy(name, description, operationType, assetId, ratioDividend, ratioDivisor, minAmount, ccu).then((data: any) => {
      console.log('CreateFeePolicy', data);
      if (data) {
        setMessage({ type: 'success', description: `Fee Policy: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit(name);
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Fee Policy: <strong>${name}</strong> ... ` });
    updateFeePolicy(props.selectedFee?.name, description, operationType, assetId, ratioDividend, ratioDivisor, minAmount, ccu).then((data: any) => {
      console.log('UpdateFee', data);
      if (data) {
        setMessage({ type: 'success', description: `Fee Policy: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(name);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.selectedFee && (
        <DialogTitle id='form-dialog-title'>Update Fee Policy</DialogTitle>
      )}
      {!props.selectedFee && (
        <DialogTitle id='form-dialog-title'>Create a new Fee Policy</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Fee Policy Details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={(e: any) => setName(e.target.value.trim())}
            disabled={!!props.selectedFee}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />

          <TextField
            id='description'
            label='description'
            value={description}
            error={errorDescription.length > 0}
            helperText={errorDescription}
            onChange={(e: any) => setDescription(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='operationType-label'>type</InputLabel>
            <Select
              labelId='operationType-label'
              id='operationType'
              label="group"
              value={operationType}
              onChange={(e: any) => setOperationType(e.target.value)}
            >
                <MenuItem value={'trade'}>trade</MenuItem>
                <MenuItem value={'bond'}>bond</MenuItem>
                <MenuItem value={'repo'}>repo</MenuItem>
                <MenuItem value={'transfer'}>transfer</MenuItem>
            </Select>
            <FormHelperText>{errorOperationType}</FormHelperText>
          </FormControl>

          <DialogContentText className={classes.text}>Default Fee Policy: </DialogContentText>

          <TextField
            id='assetId'
            label='asset ID'
            value={assetId}
            error={errorAssetId.length > 0}
            helperText={errorAssetId}
            onChange={(e: any) => setAssetId(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Grid container spacing={3} >
            <Grid item xs={5}>
              <TextField
                id='ratioDividend'
                label='ration dividend'
                value={ratioDividend}
                error={errorRatioDividend.length > 0}
                helperText={errorRatioDividend}
                onChange={(e: any) => setRatioDividend(e.target.value)}
                margin='normal'
                variant='outlined'
                type='number'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}><DialogContentText className={classes.text} style={{textAlign: 'center'}}> / </DialogContentText></Grid>
            <Grid item xs={5}>
              <TextField
                id='ratioDivisor'
                label='ration divisor'
                value={ratioDivisor}
                error={errorRatioDivisor.length > 0}
                helperText={errorRatioDivisor}
                onChange={(e: any) => setRatioDivisor(e.target.value)}
                margin='normal'
                variant='outlined'
                type='number'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid item xs={6}>
              <TextField
                id='minAmount'
                label='minimum amount'
                value={minAmount}
                error={errorMinAmount.length > 0}
                helperText={errorMinAmount}
                onChange={(e: any) => setMinAmount(e.target.value)}
                margin='normal'
                variant='outlined'
                type='number'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='ccu'
                label='ccu'
                value={ccu}
                error={errorCcu.length > 0}
                helperText={errorCcu}
                onChange={(e: any) => setCcu(e.target.value)}
                margin='normal'
                variant='outlined'
                type='number'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditFeeModal;