import * as React from 'react';

import { Category } from './Category';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Categories = (props: any) => {
  const { categories } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <h5>Categories</h5>
      {categories?.filter((category: any)=> category.totalBalance !== 0).map((category: any) => (
        <Accordion expanded={expanded === category.name} onChange={handleChange(category.name)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={category.name}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>{category.name}</Typography>
            <Typography sx={{ color: 'text.secondary' }}>{category.totalBalance.toFixed(4)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Category networks={category.networks} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}