import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { useAuthStore } from '../../hooks/context/useAuthStore';

import canvasLogo from '../../assets/logos/canvas-logo.svg';
import personIcon from '../../assets/nav/person-icon.svg';
import settingsIcon from '../../assets/nav/settings-icon.svg';
import tradeIcon from '../../assets/nav/trade-icon.svg';
import transactionIcon from '../../assets/nav/transaction-icon.svg';
import coinIcon from '../../assets/nav/coin-icon.svg';
import vaultIcon from '../../assets/nav/wallet-icon.svg';
import walletIcon from '../../assets/nav/wallet-icon.svg';
import feeIcon from '../../assets/nav/fee-icon.svg';
import documentIcon from '../../assets/nav/document-icon.svg';
import logoutIcon from '../../assets/nav/logout-icon.svg';
import loginIcon from '../../assets/nav/login-icon.svg';

import { MenuItem, MenuItemData } from './MenuItem';
import { LogoutItem } from './LogoutItem';

import { List, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { version } from '../../version';
const APP_NAME = 'Console';
const APP_VERSION = version;

console.log(`${APP_NAME} v${APP_VERSION}`)

interface IMenu {
  isOpen?: boolean;
  onClose?: () => void;
  isMobile?: boolean;
}

const useStyles = makeStyles<IMenu>()((theme: any, props: IMenu) => ({
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: (props.isOpen ? '0 30px' : '0 10px'),
  },
  divisor: {
    height: 1,
    background: '#5C5968',
  },
  logoWrapper: {
    display: 'flex',
    height: 105,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '1px solid #5C5968',
    marginBottom: 33,
    cursor: 'pointer',
    padding: 10,
  },
  mobileLogo: {
    padding: 10,
  },
  logoImgContainer: {},
  logoTitle: {
    marginTop: 8,
    color: '#fff',
    letterSpacing: 1,
    fontSize: '1.375rem',
    fontWeight: 500,
    lineHeight: '1.167',
    marginBlockStart: 0
  },
  logoutWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuWrapper: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
    overflow: 'scroll',
    display: 'flex',
    scrollbarColor: 'rgb(51, 48, 66) rgb(51, 48, 66)',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  beta: {
    width: 36,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F37222',
    color: '#Fff',
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    borderRadius: 4,
    top: 12,
    right: 72,
    marginTop: (props.isOpen ? '0' : '10px'),
    position: (props.isOpen ? 'absolute' : 'initial'),
  },
  version: {
    fontSize: '0.85em',
    textAlign: 'center',
    color: '#999',
    fontWeight: 400
  },
}));

export const Menu = ({ isOpen, onClose, isMobile }: IMenu) => {
  const { classes } = useStyles({ isOpen });

  const { isAuthorized, logout } = useAuthStore();
  const navigate = useNavigate();

  const overviewUrl = isAuthorized ? '/overview' : '/signin';
  const mainIconUrl = overviewUrl;

  const doSignIn = async () => {
    try {
      navigate('/');
    } catch (e) { }
  };

  const menu: Array<MenuItemData> = [
    {
      icon: personIcon,
      name: 'Client manager',
      to: '/users',
      onClick: onClose,
    },
    // {
    //   icon: tradeIcon,
    //   name: 'Trade',
    //   to: '/trade',
    //   onClick: onClose,
    //   isPublic: false,
    // },
    {
      icon: transactionIcon,
      name: 'Transactions',
      to: '/transactions',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: walletIcon,
      name: 'Addresses',
      to: '/addresses',
      onClick: onClose,
    },    
    {
      icon: coinIcon,
      name: 'Tokens',
      to: '/tokens',
      onClick: onClose,
    },
    {
      icon: feeIcon,
      name: 'Fees',
      to: '/fees',
      onClick: onClose,
    },    
    {
      icon: vaultIcon,
      name: 'Fireblocks',
      to: '/fireblocks',
      onClick: onClose,
    },
    {
      icon: documentIcon,
      name: 'Offchain Assets',
      to: '/offchain',
      onClick: onClose,
    },    
    {
      icon: settingsIcon,
      name: 'Settings',
      to: '/settings',
      onClick: onClose,
      isPublic: false,
    },
  ];

  return (
    <>
      <div className={clsx(classes.logoWrapper, isMobile && classes.mobileLogo)}>
        <div className={classes.logoImgContainer} onClick={() => navigate(mainIconUrl)}>
          <img src={canvasLogo} alt='logo' />
        </div>
        <div className={classes.beta}>cfx</div>
        {isOpen && (
          <>
            <Typography variant='h3' className={classes.logoTitle}> CANVAS</Typography>
            <div className={classes.version}>{APP_NAME} v{APP_VERSION}</div>
          </>
        )}
      </div>
      <div className={classes.menuWrapper}>
        <List className={classes.containerItem}>
          {menu.filter((menuItem) => isAuthorized || menuItem.isPublic).map((menuItem) => (
            <MenuItem
              isDrawerOpen={isOpen}
              menuItem={menuItem}
              key={menuItem.name}
              id={menuItem.name}
              className={menuItem.className || ''}
            />
          ))}
        </List>
        {isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: logoutIcon,
                name: 'Logout',
                onClick: logout,
              }}
              key={'Logout'}
              id={'Logout'}
              openSideBar={true}
            />
          </List>
        )}
        {!isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: loginIcon,
                name: 'Login',
                onClick: doSignIn,
              }}
              key={'Login'}
              id={'Login'}
              openSideBar={true}
            />
          </List>
        )}
      </div>
    </>
  );
}
