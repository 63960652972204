export const shrinkUUID = (uuid: string) => {
  if (uuid) {
    return `${uuid.substring(0, 6)}...${uuid.substring(uuid.length - 4)}`;
  }
  return '';
};

export const shrinkULID = (ulid: string) => {
  if (ulid) {
    return `${ulid.substring(0, 4)}...${ulid.substring(ulid.length - 4)}`;
  }
  return '';
};

export const shrinkAddress = (address: string) => {
  if (address?.length > 10) {
    return `${address.slice(0, 6)}...${address.substring(address.length - 4, address.length)}`
  }
  return address || '';
}

export const shrinkTxnHash = (address: string) => {
  if (address) {
    return `${address.slice(0, 10)}...${address.substring(address.length - 10, address.length)}`;
  }
  return '';
};

export const shrinkNumber = (s: string | number) => {
  let n = 0;
  if (typeof s === 'number') {
    n = s;
  } else if (typeof s === 'string') {
    n = Number.parseFloat(s || '');
  } 
  if (n === 0) return '0.0';
  if (n > 999999999) return n.toExponential(4);
  return n.toFixed(4);
}

export const truncate = (str: string, len: number) => (str && str.length > len ? str.substring(0, len) + '...' : str || '');

export const abbreviateNumber = (value: number, options?: { currency?: string, preciseLength?: boolean }) => {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let newValue = value;
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  const precision = newValue.toPrecision(options?.preciseLength ? value.toString().length : 3);
  return `${options?.currency === 'usd' ? '$' : ''}${precision}${suffixes[suffixNum]}`;
}

export const capitalizeLetter = (str: string) => (str && str[0].toUpperCase() + str.slice(1)) || '';
