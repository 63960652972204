import { format, formatDistance, formatDuration, intervalToDuration } from 'date-fns';

import { useAuthStore } from "../../../../hooks/context/useAuthStore";
import { TradeState } from '../../../../shared/Trade/TradeState';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
}));

export const TradeInfo = (props: any) => {
  const { currentUser } = useAuthStore();
  const { classes } = useStyles();
  return (
    <>
      {props.trade && (
        <Grid container spacing={1} alignItems="center" className={classes.grid}>
          <Grid item xs={4} className={classes.itemTitle}>Trade Id</Grid><Grid item xs={8} className={classes.itemValue}><b>{props.trade?.tradeId}</b></Grid>
          <Grid item xs={4} className={classes.itemTitle}>Counterparty</Grid><Grid item xs={8} className={classes.itemValue}>{props.trade?.l1.userId !== currentUser.userId ? props.trade?.l1.username : props.trade?.l2.username}</Grid>
          <Grid item xs={4} className={classes.itemTitle}></Grid><Grid item xs={8} className={classes.itemValue}></Grid>
          {(props.trade?.userId === currentUser.userId) && (
            <>
              <Grid item xs={4} className={classes.itemTitle}>You Send</Grid><Grid item xs={8} className={classes.itemValue}>{Number(props.trade?.amount).toFixed(2)} {props.trade?.symbolSend}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>You Receive</Grid><Grid item xs={8} className={classes.itemValue}>{(Number(props.trade?.amount) * Number(props.trade?.rate)).toFixed(2)} {props.trade?.symbolRecv}</Grid>
            </>
          )}
          {(props.trade?.userId !== currentUser.userId) && (
            <>
              <Grid item xs={4} className={classes.itemTitle}>You Send</Grid><Grid item xs={8} className={classes.itemValue}>{(Number(props.trade?.amount) * Number(props.trade?.rate)).toFixed(2)} {props.trade?.symbolRecv}</Grid>
              <Grid item xs={4} className={classes.itemTitle}>You Receive</Grid><Grid item xs={8} className={classes.itemValue}>{Number(props.trade?.amount).toFixed(2)} {props.trade?.symbolSend}</Grid>
            </>
          )}
          <Grid item xs={4} className={classes.itemTitle}>Fee</Grid><Grid item xs={8} className={classes.itemValue}>{props.trade?.l1.username === currentUser.username ? `${props.trade.l1.feeAmount.toFixed(4)} ${props.trade.l1.symbol} (${props.trade.l1.feePercent}%)` : `${props.trade.l2.feeAmount.toFixed(4)} ${props.trade.l2.symbol} (${props.trade.l2.feePercent}%)`}</Grid>
          <Grid item xs={4} className={classes.itemTitle}>Rate</Grid><Grid item xs={8} className={classes.itemValue}>{Number(props.trade?.rate).toFixed(4)} {props.trade?.symbolSend}</Grid>
          <Grid item xs={4} className={classes.itemTitle}></Grid><Grid item xs={8} className={classes.itemValue}></Grid>
          <Grid item xs={4} className={classes.itemTitle}>Created</Grid><Grid item xs={8} className={classes.itemValue}>{format(new Date(props.trade?.createdAt), 'dd-MMM-yyyy HH:mm')}</Grid>
          <Grid item xs={4} className={classes.itemTitle}>Updated</Grid><Grid item xs={8} className={classes.itemValue}>{format(new Date(props.trade?.updatedAt), 'dd-MMM-yyyy HH:mm')}</Grid>
          {(props.trade?.completedAt) && (
            <>
              <Grid item xs={4} className={classes.itemTitle}>Completed</Grid><Grid item xs={8} className={classes.itemValue}>{format(new Date(props.trade?.completedAt), 'dd-MMM-yyyy HH:mm')}</Grid>
            </>
          )}
          <Grid item xs={4} className={classes.itemTitle}></Grid><Grid item xs={8} className={classes.itemValue}></Grid>
          <Grid item xs={4} className={classes.itemTitle}>Deal Duration</Grid><Grid item xs={8} className={classes.itemValue}>{formatDistance(new Date(props.trade.createdAt), new Date(props.trade.updatedAt))}</Grid>
          <Grid item xs={4} className={classes.itemTitle}>Transaction</Grid><Grid item xs={8} className={classes.itemValue}>{formatDuration(intervalToDuration({ start: (props.trade.l2.txnAuthorizedAt ? new Date(props.trade.l2.txnAuthorizedAt) : new Date(props.trade.createdAt)), end: (props.trade.l2.txnCapturedAt ? new Date(props.trade.l2.txnCapturedAt) : new Date(props.trade.updatedAt)) }))}</Grid>
          <Grid item xs={4} className={classes.itemTitle}></Grid><Grid item xs={8} className={classes.itemValue}></Grid>
          <Grid item xs={4} className={classes.itemTitle}>Trade Status</Grid><Grid item xs={8} className={classes.itemValue}><TradeState trade={props.trade} currentUser={currentUser} /></Grid>
        </Grid>
      )}
    </>
  );
}
