import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { EditUserModal } from '../components/EditUserModal';
import { BlockUserModal } from '../components/BlockUserModal';
import { UnblockUserModal } from '../components/UnblockUserModal';
import { ResetPasswordModal } from '../components/ResetPasswordModal';
import { DeleteUserModal } from '../components/DeleteUserModal';

import { makeStyles } from 'tss-react/mui';
import { Grid, Button } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  grid: {
  },
  button: {
    width: '100%'
  }
}));

export const UserButtons = (props: any) => {
  const { data, isLoading, mutate } = props;
  const navigate = useNavigate();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openUnblockModal, setOpenUnblockModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  console.log('user', data);

  const handleOpenEditModal = () => {
    console.log('handleOpenEditModal');
    setOpenEditModal(true);
  };

  const handleOpenBlockModal = () => {
    console.log('handleOpenBlockModal');
    setOpenBlockModal(true);
  };

  const handleOpenUnblockModal = () => {
    console.log('handleOpenUnblockModal');
    setOpenUnblockModal(true);
  };

  const handleOpenPasswordModal = () => {
    console.log('handleOpenPasswordModal');
    setOpenPasswordModal(true);
  };

  const handleOpenDeleteModal = () => {
    console.log('handleOpenDeleteModal');
    setOpenDeleteModal(true);
  };

  const submitEdit = () => {
    console.log('submitEdit');
    setOpenEditModal(false);
    mutate();
  };

  const submitBlock = () => {
    console.log('submitBlock');
    setOpenBlockModal(false);
    mutate();
  };

  const submitUnblock = () => {
    console.log('submitUnblock');
    setOpenUnblockModal(false);
    mutate();
  };

  const submitPassword = () => {
    console.log('submitPassword');
    setOpenPasswordModal(false);
    mutate();
  };

  const submitDelete = () => {
    console.log('submitDelete');
    setOpenDeleteModal(false);
    navigate('/users');
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
  };

  const closeBlockModal = () => {
    console.log('closeBlock');
    setOpenBlockModal(false);
  };

  const closeUnblockModal = () => {
    console.log('closeUnblock');
    setOpenUnblockModal(false);
  };

  const closePasswordModal = () => {
    console.log('closePassword');
    setOpenPasswordModal(false);
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
  };

  const { classes } = useStyles();
  return (
    <>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading} onClick={() => handleOpenEditModal()}>Update Details</Button></Grid>
        <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading}  onClick={() => handleOpenPasswordModal()}>Reset Password</Button></Grid>
        {(data.approved) && (
          <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading}  onClick={() => handleOpenBlockModal()}>Block Account</Button></Grid>
        )}
        {(!data.approved) && (
          <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading}  onClick={() => handleOpenUnblockModal()}>Unblock Account</Button></Grid>
        )}
        <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading}  onClick={() => handleOpenDeleteModal()}>Delete Account</Button></Grid>
      </Grid>
      <EditUserModal selectedUser={data} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <BlockUserModal selectedUser={data} open={openBlockModal} closeModal={closeBlockModal} handleSubmit={submitBlock} />
      <UnblockUserModal selectedUser={data} open={openUnblockModal} closeModal={closeUnblockModal} handleSubmit={submitUnblock} />
      <ResetPasswordModal selectedUser={data} open={openPasswordModal} closeModal={closePasswordModal} handleSubmit={submitPassword} />
      <DeleteUserModal selectedUser={data} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>

  );
}