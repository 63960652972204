import { useApiTradeList } from "../../../../hooks/cfx/useApiTradeList";
import { TransactionTable } from './TransactionTable';

import Paper from "@mui/material/Paper";

export const CFXTransactions = (props: any) => {
  const { userId } = props;
  const { data, isLoading, mutate } = useApiTradeList('finished', '2000-01-01T00:00:00Z', userId);
  return (
    <>
      {data && (
        <Paper>
          <TransactionTable data={data} isLoading={isLoading} mutate={mutate} />
        </Paper>
      )}
    </>
  );
}