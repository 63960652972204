import { useState, useEffect, useMemo } from 'react';

import { updateGroupUser, getGroupUser } from '../../../services/prime-users';
import { useApiGroupList } from '../../../hooks/prime/useApiGroupList';
import { useApiUserList } from '../../../hooks/prime/useApiUserList';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const EditUserGroupModal = (props: any) => {
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiGroupList();
  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();

  const [groupId, setGroupId] = useState('');
  const [userId, setUserId] = useState('');
  const [roles, setRoles] = useState('');
  const [status, setStatus] = useState(true);

  const [errorGroup, setErrorGroup] = useState('');
  const [errorUser, setErrorUser] = useState('');
  const [errorRoles, setErrorRoles] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const group = useMemo(() => dataGroupList?.find((x: any) => x.groupId === groupId), [dataGroupList, groupId]);
  const user = useMemo(() => dataUserList?.find((x: any) => x.userId === userId), [dataUserList, userId]);

  useEffect(() => {
    if (groupId && userId) {
      getGroupUser(groupId, userId).then((result: any) => {
        console.log(result);
        if (result.status) {
          setRoles(result.data.roles || '');
          setStatus(result.data.status || false);
        }
      }).catch((e: any) => console.log(e.message));
    }
  }, [groupId, userId, dataUserList, dataGroupList])

  useEffect(() => {
    clearForm();
    clearErrors();
    setGroupId(props?.selectedGroupUser?.groupId || '');
    setUserId(props?.selectedGroupUser?.userId || '');
  }, [props.selectedGroupUser])

  const clearForm = () => {
    setGroupId('');
    setUserId('');
    setRoles('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorGroup('');
    setErrorUser('');
    setErrorRoles('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (groupId.length === 0) {
      setErrorGroup('Select the group');
      return false;
    }
    if (userId.length === 0) {
      setErrorUser('Select the user');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Adding User: <strong>${user?.name}</strong> to Group: <strong>${group?.name}</strong> ... ` });
    updateGroupUser(groupId, userId, roles, status).then((data: any) => {
      console.log('updateGroupUser', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${user?.name}</strong> added to Group: <strong>${group?.name}</strong> <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(groupId, userId);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };


  const handleMutate = () => {
    mutateUserList();
    mutateGroupList();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <DialogTitle id='form-dialog-title'>User Group Membership</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          {isLoadingUserList && (
            <IconButton><CircularProgress color="primary" size={24} /></IconButton>
          )}
          {!isLoadingUserList && (
            <IconButton color="primary" disabled={isLoadingUserList || isLoadingGroupList} onClick={() => handleMutate()}><RefreshIcon /></IconButton>
          )}
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Update the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>


          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='userId-label'>user</InputLabel>
            <Select
              labelId='userId-label'
              id='userId'
              label="user"
              value={userId}
              onChange={(e: any) => setUserId(e.target.value)}
              disabled
            >
              {dataUserList?.map((user: any) => (
                <MenuItem key={user.userId} value={user.userId}>{user.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorUser}</FormHelperText>
          </FormControl>

          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='groupId-label'>group</InputLabel>
            <Select
              labelId='groupId-label'
              id='groupId'
              label="group"
              value={groupId}
              onChange={(e: any) => setGroupId(e.target.value)}
              autoFocus
            >
              {dataGroupList?.map((group: any) => (
                <MenuItem key={group.groupId} value={group.groupId}>{group.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorGroup}</FormHelperText>
          </FormControl>

          <TextField
            id='roles'
            label='roles'
            value={roles}
            error={errorRoles.length > 0}
            helperText={errorRoles}
            onChange={(e: any) => setRoles(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e: any, val: any) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
