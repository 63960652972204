import { useState } from 'react';
import clsx from 'clsx';

import { EditUserGroupModal } from '../components/EditUserGroupModal';
import { DeleteGroupUserModal } from '../components/DeleteGroupUserModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const GroupTable = (props: any) => {
  const { data, isLoading, mutate, selectGroupUser, selectedGroupUser } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectGroupUser();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectGroupUser(id);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectGroupUser(id);
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectGroupUser(id);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectGroupUser();
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectGroupUser();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectGroupUser();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Description</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              <IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton>
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((row: any) => (
            <TableRow component="tr" key={row.groupId} onClick={() => selectGroupUser(row.groupId, row.userId)} className={clsx(classes.row, { [classes.selected]: (selectedGroupUser?.groupId === row.groupId) })}>
              <TableCell className={classes.cell} align="left"><b>{row.group?.name}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.group?.description}</TableCell>
              <TableCell className={classes.cell} align="left">{row?.status ? 'active' : 'disabled'}</TableCell>
              <TableCell className={classes.cell} align="right">
                <IconButton color="primary" onClick={() => handleOpenEditModal(row.groupId)}><EditIcon /></IconButton>
                <IconButton color="primary" onClick={() => handleOpenDeleteModal(row.groupId)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditUserGroupModal selectedGroupUser={selectedGroupUser} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteGroupUserModal selectedGroupUser={selectedGroupUser} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
