import { useMemo } from 'react';

import { useApiUserProfile } from '../../../../hooks/prime/useApiUserProfile';
import { useApiUserTransactions } from '../../../../hooks/connect/useApiUserTransactions';
import { TransactionsTable } from './TransactionsTable';

import Paper from "@mui/material/Paper";

export const ConnectTransactions = (props: any) => {
  const { userId } = props;
  const { data: user } = useApiUserProfile(userId);
  const wallet = useMemo(() => user?.metadata?.wallets?.find((x: any) => x.address.indexOf('starkkey:') === 0), [user]);
  const starkkey = useMemo(() => (wallet ? wallet.address.split(':')[1] : ''), [wallet]);
  const { data, isLoading, mutate } = useApiUserTransactions(starkkey);
  return (
    <Paper>
      <TransactionsTable data={data} isLoading={isLoading} mutate={mutate} />
    </Paper>
  );
}