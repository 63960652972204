import { TradeInfo } from './TradeInfo';
import { TransferTable } from './TransferTable';
import { TradeMessages } from '../../../../shared/Trade/TradeMessagesTable';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  close: {
    margin: theme.spacing(1)
  }  
}));
export const TransactionModal = (props: any) => {
  const handleClose = () => {
    props.closeModal();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="md" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="space-between">
        <Grid item><DialogTitle id='form-dialog-title'>Trade Details</DialogTitle></Grid>
        <Grid item><IconButton color="primary" onClick={() => handleClose()} className={classes.close}><CloseIcon /></IconButton></Grid>
      </Grid>      
      <DialogContent>

        <Grid container spacing={0} className={classes.grid}>
          <Grid item xs={5}><TradeInfo trade={props.trade} /></Grid>
          <Grid item xs={7}><TradeMessages trade={props.trade} height='348px' /></Grid>
        </Grid>
        <TransferTable trade={props.trade} />

      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => handleClose()} disableElevation>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
