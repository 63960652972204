import { useMemo } from 'react';

import { useApiUserProfile } from '../../../../hooks/prime/useApiUserProfile';
import { getWalletImage } from "../../../../helpers/networks";
import { shrinkAddress } from "../../../../helpers/shrink";

import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  title: {
    fontWeight: 500
  },
  address: {
    fontSize: '0.8rem'
  }
}));

const Wallet = (props: any) => {
  const { wallet } = props;
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs={2}><img src={getWalletImage(wallet.type)} alt={wallet.title} className={classes.image} /></Grid>
        <Grid item xs={10}><span className={classes.title}>{wallet.title}</span><br /><span className={classes.address}>{shrinkAddress(wallet.address)}</span></Grid>
      </Grid>
    </Paper>
  );
}

export const Wallets = (props: any) => {
  const { userId } = props;
  const { data: user } = useApiUserProfile(userId);
  const wallet = useMemo(() => user?.metadata?.wallets?.find((x: any) => x.address.indexOf('starkkey:') === 0), [user]);
  return (
    <>
      <h5>Wallets</h5>
      <Grid container spacing={1}>
        {user?.metadata?.wallets?.map((wallet: any) => (
          <Grid item xs={2} key={wallet.address}><Wallet wallet={wallet} /></Grid>
        ))}
      </Grid>
    </>
  );
}