import { useApiTransactions } from '../../../../hooks/prime/useApiTransactions';

import { TransactionsTable } from './TransactionsTable';

import Paper from "@mui/material/Paper";

export const PrimeTransactions = (props: any) => {
  const { userId } = props;
  const { data, isLoading, mutate } = useApiTransactions(userId);
  console.log('transactions', userId, data);
  return (
    <Paper>
      <TransactionsTable data={data} isLoading={isLoading} mutate={mutate} />
    </Paper>
  );
}