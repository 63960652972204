import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { PrimeBalances } from './tabs/PrimeBalances/PrimeBalances';
import { PrimeNFTs } from './tabs/PrimeNFTs/PrimeNFTs';
import { PrimeTrades } from './tabs/PrimeTrades/PrimeTrades';
import { PrimeTransactions } from './tabs/PrimeTransactions/PrimeTransactions';
import { ConnectTransactions } from './tabs/ConnectTransactions/ConnectTransactions';
import { CFXTransactions } from './tabs/CFXTransactions/CFXTransactions';

import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

export const UserTabs = (props: any) => {
  const { userId } = props;

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar color="secondary" position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab id={`user-tabs-0`} label="Assets" />
          <Tab id={`user-tabs-1`} label="NFTs" />
          <Tab id={`user-tabs-2`} label="Prime Trades/Swaps" />
          <Tab id={`user-tabs-3`} label="Prime Transactions" />
          <Tab id={`user-tabs-4`} label="Connect Transactions" />
          <Tab id={`user-tabs-5`} label="CFX Transactions" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}><PrimeBalances userId={userId} /></TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}><PrimeNFTs userId={userId} /></TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}><PrimeTrades userId={userId} /></TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}><PrimeTransactions userId={userId} /></TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}><ConnectTransactions userId={userId} /></TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}><CFXTransactions userId={userId} /></TabPanel>
      </SwipeableViews>
    </Box>
  );
}