import { useEffect, useState } from 'react';

import { IconBadge } from "../../../../shared/UI/IconBadge";

import EditAssetModal from './EditAssetModal';
import DeleteAssetModal from './DeleteAssetModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';

const useStyles = makeStyles()((theme) => ({
  overflow: {
    width: '100%',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.info.light,
  }
}));

const AssetTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();

  useEffect(() => {
    if (!props.walletId) {
      setSelectedAsset(undefined);
    }
  }, [props.walletId]);

  const handleAddNew = () => {
    console.log('handleAddNew');
    setSelectedAsset(undefined);
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    setSelectedAsset(data?.find((x: any) => x.assetId === id));
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    setSelectedAsset(data?.find((x: any) => x.assetId === id));
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    setSelectedAsset(undefined);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    setSelectedAsset(undefined);
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    setSelectedAsset(undefined);
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    setSelectedAsset(undefined);
  };

  const { classes } = useStyles();
  return (
    <>
        <div className={classes.overflow}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}></TableCell>
                <TableCell className={classes.cell} align="left"><b>Label</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Symbol</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Network</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Category</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Protocol</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Decimals</b></TableCell>
                <TableCell className={classes.cell} align="right"><b>Balance</b></TableCell>
                <TableCell className={classes.cell} align="right"><b>Price</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Price Source</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Status</b></TableCell>
                <TableCell className={classes.cell} align="right"><IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any) => (
                <TableRow key={row.assetId}>
                  <TableCell className={classes.cell} align="left"><IconBadge mainIcon={row.imageUrl} /></TableCell>
                  <TableCell className={classes.cell} align="left">{row.label}</TableCell>
                  <TableCell className={classes.cell} align="center">{row.symbol}</TableCell>
                  <TableCell className={classes.cell} align="center">{row.network}</TableCell>
                  <TableCell className={classes.cell} align="center">{row.category}</TableCell>
                  <TableCell className={classes.cell} align="center">{row.protocol}</TableCell>
                  <TableCell className={classes.cell} align="center">{row.decimals}</TableCell>
                  <TableCell className={classes.cell} align="right">{row.balance}</TableCell>
                  <TableCell className={classes.cell} align="right">{row.price}</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ whiteSpace: 'nowrap' }}>{row.priceId}</TableCell>
                  <TableCell className={classes.cell} align="center">{row.status ? 'active' : 'disabled'}</TableCell>
                  <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                    <IconButton onClick={() => handleOpenEditModal(row.assetId)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleOpenDeleteModal(row.assetId)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      <EditAssetModal asset={selectedAsset} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} walletId={props.walletId} />
      <DeleteAssetModal asset={selectedAsset} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
export default AssetTable;