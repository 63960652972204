import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useApiTokenConfigList } from "../../hooks/prime/useApiTokenConfigList";

import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { TokenTable } from './components/TokenTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const TokenConfig = () => {
  const { data, isLoading, mutate } = useApiTokenConfigList();
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Token Configuration</h1>
      </div>
      {isLoading && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
      {!isLoading && (
        <Paper className={classes.paper}>
          <TokenTable data={data} isLoading={isLoading} mutate={mutate} />
        </Paper>
      )}
    </AppLayout>
  );
};