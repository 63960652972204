import { Link } from "react-router-dom";
import { format } from 'date-fns';

import { getNetworkTxnLink, getNetworkName } from "../../../../helpers/networks";
import { shrinkAddress, shrinkNumber, truncate } from "../../../../helpers/shrink";

import { makeStyles } from 'tss-react/mui';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LaunchIcon from "@mui/icons-material/Launch";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: theme.spacing(1),
      height: theme.spacing(1),
      position: 'absolute'
    },
    "&::-webkit-scrollbar-track": {
      background: '#f0f1f2',
      borderRadius: '100px'
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
  tableContainer: {
    minWidth: 650,
  },
  date: {
    whiteSpace: 'nowrap'
  }
}));

const formatAction = (action: string, object: string) => {
  let s = '';
  switch (action) {
    case 'contractInteraction': break;
    default: s = action; break;
  }
  if (s && object) {
    return s + ' ' + object;
  } else if (s) {
    return s;
  }
  return object;
};

const TxnLink = ({ network, txnHash }: any) => {
  const prefix = getNetworkTxnLink(network);
  const url = (prefix ? `${prefix}${txnHash}` : '');
  return (
    <>
      {url && (
        <Link to={{ pathname: url }} style={{ color: "#000" }} target='_blank'>
          {shrinkAddress(txnHash)}
        </Link>
      )}
    </>
  );
};

const TxnLinkIcon = ({ network, txnHash }: any) => {
  const prefix = getNetworkTxnLink(network);
  const url = (prefix ? `${prefix}${txnHash}` : '');
  return (
    <>
      {url && (
        <Link to={{ pathname: url }} style={{ color: "#bbc2c9" }} target='_blank'>
          <LaunchIcon style={{ marginLeft: 2 }} />
        </Link>
      )}
    </>
  );
};

export const TransactionsTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const {classes} = useStyles();
  return (
    <TableContainer className={classes.root}>
      <Table className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Time (local)</TableCell>
            <TableCell align='center'>Network</TableCell>
            <TableCell align='center'>Action</TableCell>
            <TableCell align='center'>Contract</TableCell>
            <TableCell align='right'>Sent</TableCell>
            <TableCell align='right'>Received</TableCell>
            <TableCell align='center'>Fee</TableCell>
            <TableCell align='center'>Txn Hash</TableCell>
            <TableCell align="right" style={{ minWidth: '60px' }}>
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}              
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item: any, index: number) => (
            <TableRow key={index}>
              <TableCell align='center' className={classes.date}>{format(new Date(item.txnDate), 'dd-MMM-yyyy')}<br />{format(new Date(item.txnDate), 'H:mm a')}</TableCell>
              <TableCell align='center'>{getNetworkName(item.network)}<br />{shrinkAddress(item.walletAddress)}</TableCell>
              <TableCell align='center'><span dangerouslySetInnerHTML={{ __html: formatAction(item.action, item.object) }}></span></TableCell>
              <TableCell align='center'>{item.contract?.name}<br />{shrinkAddress(item.contract?.address)}</TableCell>
              <TableCell align='right'>
                {item.assetsSent?.filter((x: any) => x.amount || x.asset?.symbol).map((sent: any, index: number) => (
                  <div key={index}>{truncate(sent.asset?.symbol, 12)} <b>{shrinkNumber(sent.amount)}</b></div>
                ))}
              </TableCell>
              <TableCell align='right'>
                {item.assetsReceived?.filter((x: any) => x.amount || x.asset?.symbol).map((recv: any, index: number) => (
                  <div key={index}>{truncate(recv.asset?.symbol, 12)} <b>{shrinkNumber(recv.amount)}</b></div>
                ))}
              </TableCell>
              <TableCell align='center'>{item.fee?.amount ? item.fee?.currency : ''} {item.fee?.amount ? Number.parseFloat(item.fee.amount).toFixed(6) : ''}</TableCell>
              <TableCell align='center'>
              <TxnLink network={item.network} txnHash={item.txnHash} />
              </TableCell>
              <TableCell align='center'>
                <Tooltip title='Txn details'>
                <TxnLinkIcon network={item.network} txnHash={item.txnHash} />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
