import { getNetworkName } from "../../../../helpers/networks";

import { BalanceTable } from "./BalanceTable";

export const Category = (props: any) => {
  const { networks } = props;
  return (
    <>
      {networks?.map((network: any) => (
        <>
          <p>{getNetworkName(network.name)}</p>
          <BalanceTable data={network.data} />
        </>
      ))}
    </>
  );
}