import { NFTItem } from "./NFTItem";
import { getNetworkName } from "../../../../helpers/networks";

import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  network: {
    marginTop: theme.spacing(3)
  }
}));

export const NFTTable = ({ data }: any) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={3}>
      {data?.networks && data.networks.map((network: any) =>
        <>
          <Grid key={network.name} item xs={12}><Typography variant="h5" className={classes.network}>{getNetworkName(network.name)}</Typography></Grid>
          {network.items.map((item: any) => (
            <Grid key={item.addressId} item xl={3} lg={4} sm={6} xs={12}>
              <NFTItem data={item} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};
