import { api } from "./api-prime";

const request = api('api/trade');

export const getUserTranactions = async (userId: string) => {
  // console.log('getTranactions', userId);
  return await request(`transactions?userId=${userId}`);
};

export const getAllTranactions = async () => {
  return await request(`transactions`);
};