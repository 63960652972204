import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './AuthProvider.cfx';

export const ContextProvider = (props: any) => {
  const { children } = props;
  return (
    <SnackbarProvider>
      <AuthProvider>
        {children}
      </AuthProvider>
    </SnackbarProvider>
  );
};
