import useSWR from 'swr';
import { getOffchainWallets } from "../../services/prime-admin";
import { useAuthStore } from "..//context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiOffchainWallets = (_: string) => getOffchainWallets();

export const useApiOffchainWallets = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiOffchainWallets'] : null, apiOffchainWallets, options);
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};
