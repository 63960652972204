import { useState, useEffect } from 'react';
import { createToken, updateToken } from '../../../services/prime-admin';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%'
  },
  cell: {
    color: theme.palette.text.secondary,
    lineHeight: '1rem',
    padding: theme.spacing(1, 0.5)
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditTokenModal = (props: any) => {
  const [token, setToken] = useState('');
  const [network, setNetwork] = useState('');
  const [category, setCategory] = useState('');
  const [protocol, setProtocol] = useState('');
  const [symbol, setSymbol] = useState('');
  const [label, setLabel] = useState('');
  const [decimals, setDecimals] = useState('');
  const [price, setPrice] = useState('');
  const [priceId, setPriceId] = useState('');
  const [tokenImageUrl, setTokenImageUrl] = useState('');
  const [protocolImageUrl, setProtocolImageUrl] = useState('');
  const [status, setStatus] = useState(true);

  const [errorToken, setErrorToken] = useState('');
  const [errorNetwork, setErrorNetwork] = useState('');
  const [errorCategory, setErrorCategory] = useState('');
  const [errorProtocol, setErrorProtocol] = useState('');
  const [errorSymbol, setErrorSymbol] = useState('');
  const [errorLabel, setErrorLabel] = useState('');
  const [errorDecimals, setErrorDecimals] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [errorPriceId, setErrorPriceId] = useState('');
  const [errorTokenImageUrl, setErrorTokenImageUrl] = useState('');
  const [errorProtocolImageUrl, setErrorProtocolImageUrl] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    console.log('props.selectedToken', props.selectedToken);
    if (props.selectedToken) {
      setToken(props.selectedToken.token || '');
      setNetwork(props.selectedToken.network || '');
      setCategory(props.selectedToken.category || '');
      setProtocol(props.selectedToken.protocol || '');
      setSymbol(props.selectedToken.symbol || '');
      setLabel(props.selectedToken.label || '');
      setDecimals(props.selectedToken.decimals || '');
      setPrice(props.selectedToken.price || '');
      setPriceId(props.selectedToken.priceId || '');
      setTokenImageUrl(props.selectedToken.tokenImageUrl || '');
      setProtocolImageUrl(props.selectedToken.protocolImageUrl || '');
      setStatus(props.selectedToken.status || '');
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.selectedToken])

  const clearForm = () => {
    setToken('');
    setNetwork('');
    setCategory('');
    setProtocol('');
    setSymbol('');
    setLabel('');
    setDecimals('');
    setPrice('');
    setPriceId('');
    setTokenImageUrl('');
    setProtocolImageUrl('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorToken('');
    setErrorNetwork('');
    setErrorCategory('');
    setErrorProtocol('');
    setErrorSymbol('');
    setErrorLabel('');
    setErrorDecimals('');
    setErrorPrice('');
    setErrorPriceId('');
    setErrorTokenImageUrl('');
    setErrorProtocolImageUrl('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (token?.length === 0) {
      setErrorToken('Enter the token contract address.');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.selectedToken) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating token configuration: <strong>${token}</strong> ... ` });
    createToken(token, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status).then((data: any) => {
      console.log('CreateToken', data);
      if (data) {
        setMessage({ type: 'success', description: `Token Configuration: <strong>${token}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit(token, label, token, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status);
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating token configuration: <strong>${token}</strong> ... ` });
    updateToken(props.selectedToken?.token, props.selectedToken?.sk, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status).then((data: any) => {
      console.log('UpdateToken', data);
      if (data) {
        setMessage({ type: 'success', description: `Token Configuration: <strong>${token}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(props.selectedToken?.token, props.selectedToken?.sk, token, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.selectedToken && (
        <DialogTitle id='form-dialog-title'>Edit Token</DialogTitle>
      )}
      {!props.selectedToken && (
        <DialogTitle id='form-dialog-title'>Create a new Token</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.selectedToken && (
          <DialogContentText>{props.selectedToken.info.symbol} - {props.selectedToken.info.label}</DialogContentText>
        )}
        {!props.selectedToken && (
          <DialogContentText>Input the configuration details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id='token'
                label='token'
                value={token}
                error={errorToken.length > 0}
                helperText={errorToken}
                onChange={(e: any) => setToken(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='network'
                label='network'
                value={network}
                error={errorNetwork.length > 0}
                helperText={errorNetwork}
                onChange={(e: any) => setNetwork(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id='category'
                label='category'
                value={category}
                error={errorCategory.length > 0}
                helperText={errorCategory}
                onChange={(e: any) => setCategory(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='protocol'
                label='protocol'
                value={protocol}
                error={errorProtocol.length > 0}
                helperText={errorProtocol}
                onChange={(e: any) => setProtocol(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id='label'
                label='label'
                value={label}
                error={errorLabel.length > 0}
                helperText={errorLabel}
                onChange={(e: any) => setLabel(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='symbol'
                label='symbol'
                value={symbol}
                error={errorSymbol.length > 0}
                helperText={errorSymbol}
                onChange={(e: any) => setSymbol(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>          
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                id='decimals'
                label='decimals'
                value={decimals}
                error={errorDecimals.length > 0}
                helperText={errorDecimals}
                onChange={(e: any) => setDecimals(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id='price'
                label='price'
                value={price}
                error={errorPrice.length > 0}
                helperText={errorPrice}
                onChange={(e: any) => setPrice(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='priceId'
                label='priceId'
                value={priceId}
                error={errorPriceId.length > 0}
                helperText={errorPriceId}
                onChange={(e: any) => setPriceId(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>          
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id='tokenImageUrl'
                label='Token Image'
                value={tokenImageUrl}
                error={errorTokenImageUrl.length > 0}
                helperText={errorTokenImageUrl}
                onChange={(e: any) => setTokenImageUrl(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='protocolImageUrl'
                label='Protocol Image'
                value={protocolImageUrl}
                error={errorProtocolImageUrl.length > 0}
                helperText={errorProtocolImageUrl}
                onChange={(e: any) => setProtocolImageUrl(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>           

          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e: any, val: any) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
export default EditTokenModal;