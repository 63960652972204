import useSWR from 'swr';
import { useAuthStore } from "../context/useAuthStore";
import { getFireblocksExchanges } from "../../services/prime-admin";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiFireblocksExchanges = () => getFireblocksExchanges();

export const useApiFireblocksExchanges = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiFireblocksExchanges'] : null, apiFireblocksExchanges, options);
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
