import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles()((theme) => ({
  chip: {
    textTransform: 'uppercase',
    fontWeight: 500
  }
}));

export const TradeState = (props: any) => {
  const { classes } = useStyles();
  const transfer = useMemo(()=> props.trade?.transfers.find((x: any) => x.userId === props.currentUser.userId), [props.trade, props.currentUser]);
  const counterTransfer = useMemo(()=> props.trade?.transfers.find((x: any) => x.userId !== props.currentUser.userId), [props.trade, props.currentUser]);
  if (transfer?.state === 'CREATED') {
    return <Chip color='info' size='small' label='open' className={classes.chip} />;
  }
  if (transfer?.state === 'CONFIRMED') {
    return <Chip color='default' size='small' label='confirmed' className={classes.chip} />;
  }
  if (counterTransfer?.state === 'CONFIRMED') {
    return <Chip color='default' size='small' label='pending' className={classes.chip} />;
  }
  switch (props.trade.state) {
    case 'CREATED': return <Chip color='info' size='small' label='open' className={classes.chip} />;
    case 'ENQUEUED': return <Chip color='default' size='small' label='proceeding' className={classes.chip} />;
    case 'PENDING': return <Chip color='default' size='small' label='proceeding' className={classes.chip} />;
    case 'PROCESSING': return <Chip color='warning' size='small' label='processing' className={classes.chip} />;
    case 'COMPLETED': return <Chip color='success' size='small' label='completed' className={classes.chip} />;
    case 'CANCELLED': return <Chip color='error' size='small' label='cancelled' className={classes.chip} />;
    case 'FAILED': return <Chip color='error' size='small' label='failed' className={classes.chip} />;
    default: return <Chip color='default' label={props.state} />;
  }
};
