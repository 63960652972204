import { format } from "date-fns";
import { shrinkULID } from "../../../../helpers/shrink";

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
    marginTop: theme.spacing(5)
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    fontSize: '0.8rem',
    fontWeight: 300,
    lineHeight: '1rem',
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap'
  },
  heading: {
    fontSize: '0.8rem',
    fontWeight: 500,
  },
  symbol: {
    fontWeight: 500
  },
}));

const formatDate = (dt: string | undefined) => {
  if (dt) {
    return format(new Date(dt), 'dd-MMM-yyyy HH:mm:ss')
  }
  return '';
}

export const TransferTable = (props: any) => {
  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>User</span></TableCell>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>From</span></TableCell>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>To</span></TableCell>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>Amount</span></TableCell>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>Asset</span></TableCell>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>Txn Id</span></TableCell>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>Completed</span></TableCell>
          <TableCell className={classes.cell} align="left"><span className={classes.heading}>Status</span></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props?.trade?.transfers?.map((transfer: any) => (
          <TableRow component="tr">
            <TableCell className={classes.cell} align="center">{transfer.username}</TableCell>
            <TableCell className={classes.cell} align="center">{shrinkULID(transfer.from)}</TableCell>
            <TableCell className={classes.cell} align="center">{shrinkULID(transfer.to)}</TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.symbol}>{Number(transfer.amount || '0').toFixed(2)}</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.symbol}>{transfer.symbol}</span></TableCell>
            <TableCell className={classes.cell} align="center">{shrinkULID(transfer.txnHash)}</TableCell>
            <TableCell className={classes.cell} align="center">{formatDate(transfer.txnCompletedAt)}</TableCell>
            <TableCell className={classes.cell} align="left">{transfer.state}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
