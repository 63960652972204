import React from "react";

import { makeStyles } from 'tss-react/mui';
import { Skeleton } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles()((theme) => ({
  skeleton: {
    width: "100%",
    display: "flex",
    boxShadow: "0 4px 1px -3px #b3b1ad",
    //   boxShadow: '0px 4px 20px -5px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    transform: "none",
    alignItems: "center",
  },
}));

interface ISkeletonProps {
  className?: string;
  style?: React.CSSProperties;
  height?: number;
  width?: number;
}

export const CanvasSkeleton = ({ className, style, width, height }: ISkeletonProps) => {
  const { classes } = useStyles();
  return <Skeleton variant="rectangular" animation="pulse" width={width} height={height} className={clsx(classes.skeleton, className)} style={style} />;
};
