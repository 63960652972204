import { useEffect, useState, useMemo } from 'react';

import { useApiGroupUserList } from '../../hooks/prime/useApiGroupUserList';
import { useApiGroupList } from '../../hooks/prime/useApiGroupList';
import { useApiUserList } from '../../hooks/prime/useApiUserList';
import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useNavigate } from "react-router-dom";

import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from './components/GroupTable';
import { UserTable } from './components/UserTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const Users = () => {

  const { isAuthorized } = useAuthStore();
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();

  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiGroupList();
  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();
  const { data: dataGroupUsers, isLoading: isLoadingGroupUsers, mutate: mutateGroupUsers } = useApiGroupUserList();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectGroup = (id?: string) => {
    const group = dataGroupList?.find((x: any) => x.groupId === id);
    setSelectedGroup(group);
    setSelectedUser(undefined);
  }

  const selectUser = (id?: string) => {
    const user = dataUserList?.find((x: any) => x.userId === id);
    setSelectedUser(user);
  }

  const mutate = () => {
    mutateGroupList();
    mutateUserList();
    mutateGroupUsers();
  }

  const isLoading = useMemo(() => isLoadingGroupUsers || isLoadingGroupUsers|| isLoadingUserList, [selectedGroup, isLoadingGroupUsers, isLoadingUserList]);

  const users = useMemo(() => {
    if (!selectedGroup) return dataUserList;
    const userGroups = dataGroupUsers?.filter((x: any) => x.groupId === selectedGroup.groupId);
    return dataUserList?.filter((x: any) => userGroups?.find((y: any) => y.userId === x.userId))
  }, [selectedGroup, dataGroupUsers, dataUserList]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Client Manager</h1>
      </div>
      {(!Array.isArray(dataGroupList)) && <CanvasSkeleton className={classes.skeleton} />}
      {(Array.isArray(dataGroupList)) && (
        <Paper className={classes.paper}>
          <GroupTable data={dataGroupList} isLoading={isLoadingGroupList || isLoadingGroupUsers} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
        </Paper>
      )}

      {(!Array.isArray(users)) && <CanvasSkeleton className={classes.skeleton} style={{ height: '500px' }} />}
      {(Array.isArray(users)) && (
        <Paper className={classes.paper}>
          <UserTable data={users} isLoading={isLoading} mutate={mutate} selectUser={selectUser} selectedUser={selectedUser} selectedGroup={selectedGroup} />
        </Paper>
      )}
    </AppLayout>
  );
};
