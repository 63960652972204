import { useState } from "react";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useApiBalancePortfolio } from "../../hooks/cfx/useApiBalancePortfolio";
import { refreshBalancePortfolio, WaitForBalance } from "../../services/cfx-balance";

import { CanvasSkeleton } from "../UI/CanvasSkeleton";
import { AccountTable } from "./AccountTable";

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const Account = (props: any) => {
  const { currentUser } = useAuthStore();
  const { data: dataBalance, mutate: mutateBalance } = useApiBalancePortfolio();
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);

  const refreshBalance = () => {
    if (!isRefreshingBalance) {
      setIsRefreshingBalance(true);
      refreshBalancePortfolio().then(() => {
        WaitForBalance()
          .then((wait: boolean) => mutateBalance())
          .catch((e: any) => console.log(e))
          .finally(() => setIsRefreshingBalance(false));
      });
    }
  };
  const { classes } = useStyles();
  return (
    <>
      {(!dataBalance || dataBalance.length === 0 || !currentUser?.username) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {(dataBalance?.length > 0) && currentUser?.username && (
        <Paper className={classes.paper}>
          <h2 className={classes.title}>CFX Account</h2>
          <AccountTable data={dataBalance} mutate={refreshBalance} isLoading={isRefreshingBalance} />
        </Paper>
      )}
    </>
  );
}