import { useApiBalance } from '../../hooks/prime/useApiBalance';

import { CanvasSkeleton } from "../UI/CanvasSkeleton";

import { makeStyles } from 'tss-react/mui';
import { Paper, Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  },
  mono: {
    fontFamily: 'monospace',
    fontWeight: 700
  }  

}));

export const PrimeBalance = (props: any) => {
  const { userId } = props;
  const { data, isLoading, mutate } = useApiBalance(userId);
  const { classes } = useStyles();
  return (
    <>
      {!data && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {data && (
        <Paper className={classes.paper}>
          <h2 className={classes.title}>Prime Balance</h2>
          <p>Grand Total: <span className={classes.mono}>{data.totalBalanceUSD.toFixed(2)}</span> USD</p>          
        </Paper>
      )}
    </>
  );
}