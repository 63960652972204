import { useState } from 'react';
import { deleteToken } from "../../../services/prime-admin";

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  content: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  }
}));

const DeleteTokenModal = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
  };

  const handleSubmit = () => {
    console.log('handleSubmit');
    setLoading(true);
    setMessage({ type: 'warning', description: `Deleting configuration for token: <strong>${props.selectedToken?.symbol}</strong> ... ` });
    deleteToken(props.selectedToken?.token, props.selectedToken?.sk).then((data) => {
      console.log('DeleteToken', data);
      if (data) {
        setMessage({ type: 'success', description: `Configuration for token: <strong>${props.selectedToken?.symbol}</strong> has been deleted <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(props.selectedToken?.token, props.selectedToken?.sk);
        clearErrors();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete Token Configuration</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Are you sure you want to <strong>delete</strong> the configuration for this token:</DialogContentText>
        <div className={classes.content}>
          <h3>{props.selectedToken?.token}</h3>
          <h6>( {props.selectedToken?.symbol} ) {props.selectedToken?.label}</h6>
        </div>
        {message && message.description &&
          <Alert
            variant='outlined'
            severity={message.type as any}
            className={classes.alert as any}
            action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
          >
            <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary">Cancel</Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained" disabled={isLoading} disableElevation>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteTokenModal;