import { useState } from 'react';
import { shrinkAddress } from '../../../helpers/shrink';

import EditFeePolicyModal from './EditFeePolicyModal';
import DeleteFeePolicyModal from './DeleteFeePolicyModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme: any) => ({
  table: {
    width: '100%'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
}));

export const FeePolicyTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedFee, setSelectedFee] = useState<any>();

  const selectFee = (id?: string) => {
    const fee = data?.find((x: any) => x.name === id);
    setSelectedFee(fee);
  }

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectFee();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectFee(id);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectFee(id);
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectFee(id);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectFee();
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectFee();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectFee();
  };

  const { classes } = useStyles();
  return (
    <>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>Name</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Description</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Type</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Asset</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Min Amount</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Ratio</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>CCU</b></TableCell>
            <TableCell className={classes.cell} align="right">
              <IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton>
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row: any) => (
            <TableRow key={row.name} component="tr">
              <TableCell className={classes.cell} align="left" component="th" scope="row"><h5>{row.name}</h5></TableCell>
              <TableCell className={classes.cell} align="left">{row.description}</TableCell>
              <TableCell className={classes.cell} align="center">{row.type || row.operationType}</TableCell>
              <TableCell className={classes.cell} align="center">{shrinkAddress(row.defaultFee?.asset_id)}</TableCell>
              <TableCell className={classes.cell} align="center">{row.defaultFee?.min_amount}</TableCell>
              <TableCell className={classes.cell} align="center">{row.defaultFee?.ratio?.dividend / row.defaultFee?.ratio?.divisor}</TableCell>
              <TableCell className={classes.cell} align="center">{row.defaultFee?.ccu}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                <IconButton onClick={() => handleOpenEditModal(row.name)}><EditIcon /></IconButton>
                <IconButton onClick={() => handleOpenDeleteModal(row.name)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditFeePolicyModal selectedFee={selectedFee} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteFeePolicyModal selectedFee={selectedFee} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
